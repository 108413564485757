import { moveItemInArray } from '@angular/cdk/drag-drop';
import { Injectable } from '@angular/core';

import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, take, withLatestFrom } from 'rxjs/operators';
import {
  RimsTableColumnSortDialogComponent,
  RimsTableColumnSortDialogInput
} from '../../shared/components/rims-table-column-sort-dialog/rims-table-column-sort-dialog.component';
import {
  RimsTableFilterCreationDialogComponent,
  RimsTableFilterCreationDialogInput
} from '../../shared/components/rims-table-filter-creation-dialog/rims-table-filter-creation-dialog.component';
import { getQueryParamKey, ViewQueryParam } from '../../shared/components/rims-table/rims-table-query-params';
import { View } from '../../shared/models/view/view.model';
import { MetadataService } from '../../shared/services/metadata/metadata.service';
import { difference, uniqueElementsBy } from '../../shared/utils/array-utils';
import { columnIdsFromNames } from '../../shared/utils/columns';
import { getData } from '../data/data.actions';
import { noop } from '../shared/shared.actions';
import { AppState } from '../store.state';
import { loginSuccess } from '../user/user.actions';
import {
  clearDraggedColumn,
  closeColumnPreferencesDialog,
  dropColumn,
  getEntities,
  getEntitiesError,
  getEntitiesSuccess,
  getFieldsForEntity,
  getFieldsForEntityCheck,
  getFieldsForEntityError,
  getFieldsForEntitySuccess,
  getPermissions,
  getPermissionsError,
  getPermissionsSuccess,
  getViewConfig,
  getViewConfigError,
  getViewConfigSuccess,
  getViewForField,
  getViewForFieldRelation,
  getViewGroups,
  getViewGroupsError,
  getViewGroupsSuccess,
  getViewsError,
  getViewsSuccess,
  openColumnPreferencesDialog,
  openFilterCreationDialog,
  resetColumnPreferences,
  resetUrlQueryPagination,
  saveColumns,
  setFilters,
  setFiltersSuccess
} from './metadata.actions';

@Injectable()
export class MetadataEffects {
  getViewForField = createEffect(() =>
    this.actions.pipe(
      ofType(getViewForField),
      switchMap(payload => {
        return this.metadataService.getViewForField(payload.fieldId).pipe(
          switchMap(views => of(getViewsSuccess(views))),
          catchError(err => of(getViewsError(err)))
        );
      })
    )
  );

  getViewForFieldRelation = createEffect(() =>
    this.actions.pipe(
      ofType(getViewForFieldRelation),
      switchMap(payload => {
        return this.metadataService.getViewForFieldRelation(payload.fieldId).pipe(
          switchMap(views => of(getViewsSuccess(views))),
          catchError(err => of(getViewsError(err)))
        );
      })
    )
  );

  getViews = createEffect(() =>
    this.actions.pipe(
      ofType(loginSuccess),
      switchMap(() => {
        return this.metadataService.getViews().pipe(
          switchMap(views => of(getViewsSuccess(views))),
          catchError(err => of(getViewsError(err)))
        );
      })
    )
  );

  getFieldsForEntityCheck = createEffect(() =>
    this.actions.pipe(
      ofType(getFieldsForEntityCheck),
      withLatestFrom(this.store.pipe(select(state => state.metadata))),
      map(([props, metadata]) => {
        if (metadata.entities[props.entityId]?.fieldDataRequested) {
          return noop();
        }
        return getFieldsForEntity(props);
      })
    )
  );

  getFieldsForEntity = createEffect(() =>
    this.actions.pipe(
      ofType(getFieldsForEntity),
      mergeMap(payload => {
        return this.metadataService.getFields(payload.entityId).pipe(
          switchMap(page =>
            of(
              getFieldsForEntitySuccess({
                page,
                entityId: payload.entityId
              })
            )
          ),
          catchError(err => of(getFieldsForEntityError(err)))
        );
      })
    )
  );

  getFieldsForEntityError = createEffect(() =>
    this.actions.pipe(
      ofType(getFieldsForEntityError),
      switchMap(err => {
        if (err) {
          console.error(err);
        }
        this.snackBar.open(`❌  Could not load fields for this entity. Please contact your administrator.`, null, {
          duration: 4000,
          horizontalPosition: 'right'
        });
        return of(noop());
      })
    )
  );

  getPermissions = createEffect(() =>
    this.actions.pipe(
      ofType(getPermissions),
      switchMap(() => {
        return this.metadataService.getPermissions().pipe(
          switchMap(permissions => of(getPermissionsSuccess(permissions))),
          catchError(err => of(getPermissionsError(err)))
        );
      })
    )
  );

  getPermissionsError = createEffect(() =>
    this.actions.pipe(
      ofType(getPermissionsError),
      switchMap(err => {
        if (err) {
          console.error(err);
        }
        this.snackBar.open(`❌  Could not load permissions. Please contact your administrator.`, null, {
          duration: 4000,
          horizontalPosition: 'right'
        });
        return of(noop());
      })
    )
  );

  getEntities = createEffect(() =>
    this.actions.pipe(
      ofType(getEntities),
      switchMap(() => {
        return this.metadataService.getEntities().pipe(
          switchMap(entities => of(getEntitiesSuccess(entities))),
          catchError(err => of(getEntitiesError(err)))
        );
      })
    )
  );

  getEntitiesSuccess = createEffect(() =>
    this.actions.pipe(
      ofType(getEntitiesSuccess),
      map(_ => getPermissions())
    )
  );

  getEntitiesError = createEffect(() =>
    this.actions.pipe(
      ofType(getEntitiesError),
      switchMap(err => {
        if (err) {
          console.error(err);
        }
        this.snackBar.open(`❌  Could not load entities. Please contact your administrator.`, null, {
          duration: 4000,
          horizontalPosition: 'right'
        });
        return of(noop());
      })
    )
  );

  getViewGroups = createEffect(() =>
    this.actions.pipe(
      ofType(getViewGroups),
      switchMap(() => {
        return this.metadataService.getViewGroups().pipe(
          switchMap(groups => of(getViewGroupsSuccess(groups))),
          catchError(err => of(getViewGroupsError(err)))
        );
      })
    )
  );

  getViewGroupsError = createEffect(() =>
    this.actions.pipe(
      ofType(getViewGroupsError),
      switchMap(err => {
        if (err) {
          console.error(err);
        }
        this.snackBar.open(`❌  Could not load view groups. Please contact your administrator.`, null, {
          duration: 4000,
          horizontalPosition: 'right'
        });
        return of(noop());
      })
    )
  );

  getViewsError = createEffect(() =>
    this.actions.pipe(
      ofType(getViewsError),
      switchMap(err => {
        if (err) {
          console.error(err);
        }
        this.snackBar.open(`❌  Could not get views`, null, {
          duration: 4000,
          horizontalPosition: 'right'
        });
        return of(noop());
      })
    )
  );

  getViewConfig = createEffect(() =>
    this.actions.pipe(
      ofType(getViewConfig),
      mergeMap(({ viewId, columnNames, filters, url }) => {
        return this.metadataService.getColumns(viewId).pipe(
          map(config => {
            const newView: View = {
              ...config.view,
              columns: config.columns,
              preferences: config.preferences,
              displayedColumns: [],
              columnIds: columnIdsFromNames(columnNames, config)
            };

            newView.displayedColumns = config.columns
              .map(col => {
                const preference = config.preferences.find(pref => pref.column === col.id);
                if (preference) {
                  col.hide = !col.showAlways && preference.hide;
                  col.defaultOrder = preference.order;
                }
                return col;
              })
              .filter(col => !col.hide)
              .sort((a, b) => a.defaultOrder - b.defaultOrder);

            return newView;
          }),
          switchMap(view =>
            of(
              getViewConfigSuccess({
                view,
                filters,
                url
              })
            )
          ),
          catchError(err => of(getViewConfigError(err)))
        );
      })
    )
  );

  getViewConfigSuccess = createEffect(() =>
    this.actions.pipe(
      ofType(getViewConfigSuccess),
      withLatestFrom(this.route.queryParams),
      mergeMap(([props, params]) => {
        const relationActions = props.view.columns
          .filter((col, i, arr) => {
            const relation = col.field2.relation;
            const unique = arr.findIndex(col2 => col.field2.relation === col2.field2.relation) === i;
            const sameAsView = props.view.route.endsWith(`/${relation}`);
            return relation && unique && !sameAsView;
          })
          .map(col =>
            getData({
              key: col.field2.relation.toUpperCase(),
              url: col.field2.relation,
              pageSize: 100
            })
          );

        // Use the view parameters from the url for the initial data load
        const paramKey = getQueryParamKey(props.view.id);
        let decodedParam: ViewQueryParam;

        if (Object.prototype.hasOwnProperty.call(params, paramKey)) {
          decodedParam = ViewQueryParam.decode(params[paramKey]);
          decodedParam.o = props.view.columns.find(col => col.id === Number(decodedParam.o))?.field2?.field;
        }

        const filters = decodedParam?.f || props.filters;

        return [
          setFilters({
            viewId: props.view.id,
            filters
          }),
          ...relationActions,
          getFieldsForEntityCheck({
            entityId: typeof props.view.entity === 'number' ? props.view.entity : props.view.entity.id
          })
        ];
      })
    )
  );

  setFilters = createEffect(() =>
    this.actions.pipe(
      ofType(setFilters),
      map(({ viewId, filters }) => {
        return setFiltersSuccess({ viewId, filters });
      })
    )
  );

  resetColumnPreferences = createEffect(() =>
    this.actions.pipe(
      ofType(resetColumnPreferences),
      switchMap(payload => {
        return this.metadataService.resetColumns(payload.viewId).pipe(
          switchMap(res => {
            return [
              resetUrlQueryPagination({ viewId: payload.viewId }),
              getViewConfig({ viewId: payload.viewId }),
              closeColumnPreferencesDialog()
            ];
          })
        );
      })
    )
  );

  saveColumns = createEffect(() =>
    this.actions.pipe(
      ofType(saveColumns),
      withLatestFrom(this.store.pipe(select(state => state.metadata))),
      switchMap(([payload, metadata]) => {
        const oldDisplayColumns = metadata.views[metadata.columnPreferencesDialog.viewId].displayedColumns;
        const newDisplayColumns = metadata.columnPreferencesDialog.selected;

        const changedColumns = difference(newDisplayColumns, oldDisplayColumns);
        const addedColumns = newDisplayColumns
          .filter(x => !oldDisplayColumns.find(y => y.id === x.id))
          .map(col => ({ ...col, hide: false }));
        const removedColumns = oldDisplayColumns
          .filter(x => !newDisplayColumns.find(y => y.id === x.id))
          .map(col => ({ ...col, hide: true }));

        const allChangedColumns = uniqueElementsBy(
          [...addedColumns, ...removedColumns, ...changedColumns],
          (a, b) => a.id === b.id
        );

        if (allChangedColumns.length === 0) {
          return of(closeColumnPreferencesDialog());
        }

        return this.metadataService.updateColumns(metadata.columnPreferencesDialog.viewId, allChangedColumns).pipe(
          catchError(err => {
            this.snackBar.open(`❌  Could not update columns preferences`, null, {
              duration: 4000,
              horizontalPosition: 'right'
            });
            return of(undefined);
          }),
          switchMap(() => {
            return [getViewConfig({ viewId: metadata.columnPreferencesDialog.viewId }), closeColumnPreferencesDialog()];
          })
        );
      })
    )
  );

  resetUrlQueryPagination = createEffect(() =>
    this.actions.pipe(
      ofType(resetUrlQueryPagination),
      switchMap(payload => {
        return this.route.queryParams.pipe(
          take(1),
          switchMap(params => {
            const key = getQueryParamKey(payload.viewId);
            const viewParam = params[key];
            if (viewParam) {
              const newParams = {
                ...params
              };
              delete newParams[key];
              this.router.navigate([], {
                queryParams: newParams
              });
            }
            return of(noop());
          })
        );
      })
    )
  );

  openColumnPreferencesDialog = createEffect(() =>
    this.actions.pipe(
      ofType(openColumnPreferencesDialog),
      switchMap(payload => {
        this.dialog.open<RimsTableColumnSortDialogComponent, RimsTableColumnSortDialogInput>(
          RimsTableColumnSortDialogComponent,
          {
            minWidth: '60%',
            maxWidth: '100%',
            data: payload
          }
        );
        return of(noop());
      })
    )
  );

  openFilterCreationDialog = createEffect(() =>
    this.actions.pipe(
      ofType(openFilterCreationDialog),
      switchMap(payload => {
        this.dialog.open<RimsTableFilterCreationDialogComponent, RimsTableFilterCreationDialogInput>(
          RimsTableFilterCreationDialogComponent,
          {
            position: { top: '270px' },
            panelClass: ['filter-creation', 'custom-dialog-width'],
            data: payload
          }
        );
        return of(noop());
      })
    )
  );

  dropColumn = createEffect(() =>
    this.actions.pipe(
      ofType(dropColumn),
      withLatestFrom(this.store.select(state => state.metadata)),
      switchMap(([payload, metadata]) => {
        const viewId = metadata.draggedColumn.viewId;

        // Create a copy of displayColumns to work on
        const cols = [...metadata.views[viewId].displayedColumns];
        // Find the array-index of the column to change
        const oldIndex = cols.findIndex(col => col.id === metadata.draggedColumn.columnId);

        // Move item according to payload
        moveItemInArray(cols, oldIndex, payload.newIndex);

        // Find the neighbor items (2 if in the middle, 1 if at the start/end)
        const adjacentCols = cols.filter((_, index) => Math.abs(index - payload.newIndex) <= 1);
        // Find the position of the column to change and the column itself to modify
        const indexOfColToChange = adjacentCols.findIndex(col => col.id === metadata.draggedColumn.columnId);
        const colToChange = { ...adjacentCols[indexOfColToChange] };

        // Depending on the position, change the order of the column
        switch (adjacentCols.length) {
          case 2: {
            switch (indexOfColToChange) {
              case 0:
                colToChange.defaultOrder = adjacentCols[1].defaultOrder - 1;
                break;
              case 1:
                colToChange.defaultOrder = adjacentCols[0].defaultOrder + 1;
                break;
            }
            break;
          }
          case 3: {
            colToChange.defaultOrder = (adjacentCols[0].defaultOrder + adjacentCols[2].defaultOrder) / 2;
          }
        }

        return this.metadataService.updateColumns(viewId, [colToChange]).pipe(
          catchError(err => {
            this.snackBar.open(`❌  Could not update columns preferences`, null, {
              duration: 4000,
              horizontalPosition: 'right'
            });
            return of(undefined);
          }),
          switchMap(() => {
            return [getViewConfig({ viewId }), clearDraggedColumn()];
          })
        );
      })
    )
  );

  constructor(
    private readonly store: Store<AppState>,
    private readonly actions: Actions,
    private readonly metadataService: MetadataService,
    private readonly snackBar: MatSnackBar,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly dialog: MatDialog
  ) {}
}
