import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EqualsFilter } from '@rims/lib';
import { of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { DataService } from '../../shared/services/data/data.service';
import { createRecord } from '../../store/data/data.actions';
import { navigateTo } from '../../store/router/router.actions';
import { createDocument, createExtDmsObjectAndDocument } from './documents.actions';

@Injectable()
export class DocumentsEffects {
  constructor(private readonly actions: Actions, private readonly dataService: DataService) {}

  createExtDmsObjectAndDocument = createEffect(() =>
    this.actions.pipe(
      ofType(createExtDmsObjectAndDocument),
      switchMap(payload => {
        if (payload.dmsId) {
          return this.dataService
            .getAll('extdmsobjects', undefined, undefined, undefined, undefined, undefined, [
              new EqualsFilter('dmsId', payload.dmsId)
            ])
            .pipe(
              switchMap(page => {
                if (page.resultsSize === 0) {
                  return this.dataService.create('extdmsobjects', {
                    ...payload,
                    isFolder: !!payload.isFolder
                  });
                } else {
                  return of(page.results);
                }
              }),
              map(dmsObjects => {
                if (payload.documentType) {
                  return createDocument({
                    ...payload,
                    dmsObject: dmsObjects[0].id
                  });
                } else {
                  return navigateTo({
                    routerLink: `/view/extdmsobjects/${dmsObjects[0].id}`
                  });
                }
              })
            );
        } else {
          return of(
            payload.documentType
              ? createDocument(payload)
              : navigateTo({
                  routerLink: '/view/documents'
                })
          );
        }
      })
    )
  );

  createDocument = createEffect(() =>
    this.actions.pipe(
      ofType(createDocument),
      switchMap(payload => {
        return of(
          createRecord({
            url: 'documents',
            payload,
            navigateTo: records => `/view/documents/${records[0].id}`
          })
        );
      })
    )
  );
}
