import { animate, style, transition, trigger } from '@angular/animations';
import { AsyncPipe, NgIf } from '@angular/common';
import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyOptionModule } from '@angular/material/legacy-core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
  MatLegacyDialogModule
} from '@angular/material/legacy-dialog';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacySelectModule } from '@angular/material/legacy-select';
import { Store } from '@ngrx/store';
import { isPopulatedArray } from '@rims/lib';
import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AppState } from 'src/app/modules/store/store.state';
import type { OpenExportDialogPayload } from '../../../store/data/data.actions';
import { closeExportDialog } from '../../../store/data/data.actions';
import { View } from '../../models/view/view.model';
import { ViewParamPipe } from '../../pipes/view-param.pipe';
import { columnsToExpandQuery } from '../../utils/columns';
import { filtersToExpandQuery } from '../../utils/expand';

enum FieldNames {
  MODE = 'MODE',
  FORMAT = 'FORMAT'
}

enum ExportMode {
  CURRENT_PAGE,
  IGNORE_PAGING,
  EXPORT_ALL
}

@Component({
  selector: 'rims-rims-table-export-dialog',
  templateUrl: './rims-table-export-dialog.component.html',
  styleUrls: ['./rims-table-export-dialog.component.scss'],
  animations: [
    trigger('openClose', [transition(':enter', [style({ opacity: 0 }), animate('.1s', style({ opacity: 1 }))])])
  ],
  standalone: true,
  imports: [
    MatLegacyDialogModule,
    FormsModule,
    ReactiveFormsModule,
    MatLegacyFormFieldModule,
    MatLegacySelectModule,
    MatLegacyOptionModule,
    NgIf,
    MatLegacyButtonModule,
    AsyncPipe,
    ViewParamPipe
  ]
})
export class RimsTableExportDialogComponent implements OnInit, OnDestroy {
  view: Observable<View>;

  form = new UntypedFormGroup({
    [FieldNames.FORMAT]: new UntypedFormControl('csv', Validators.required),
    [FieldNames.MODE]: new UntypedFormControl(ExportMode.CURRENT_PAGE, Validators.required)
  });
  readonly data: OpenExportDialogPayload = inject(MAT_DIALOG_DATA);
  readonly fieldNames = FieldNames;
  readonly exportMode = ExportMode;

  private sub: Subscription;

  constructor(
    private readonly dialogRef: MatDialogRef<RimsTableExportDialogComponent>,
    private readonly store: Store<AppState>
  ) {}

  ngOnInit(): void {
    this.view = this.store.select(state => state.metadata.views[this.data.viewId]);
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  onSubmit() {
    this.sub = this.view
      .pipe(
        tap(view => {
          const mode = this.form.value[FieldNames.MODE];
          let expand = columnsToExpandQuery(view.displayedColumns);

          if (mode !== ExportMode.EXPORT_ALL && isPopulatedArray(this.data.viewParam.f)) {
            expand = filtersToExpandQuery(expand, this.data.viewParam.f);
          }

          this.store.dispatch(
            closeExportDialog({
              entityId: typeof view.entity === 'object' ? view.entity.id : view.entity,
              format: this.form.value[FieldNames.FORMAT],
              page: mode === ExportMode.CURRENT_PAGE ? this.data.viewParam.i || 0 : undefined,
              pageSize: mode === ExportMode.CURRENT_PAGE ? this.data.viewParam.s : undefined,
              exportAll: mode === ExportMode.EXPORT_ALL || mode === ExportMode.IGNORE_PAGING,
              sort: {
                active: view.columns.find(col => `${col.id}` === this.data.viewParam.o)?.field2.field,
                direction: this.data.viewParam.d
              },
              filter: mode !== ExportMode.EXPORT_ALL ? this.data.viewParam.f : undefined,
              query: mode !== ExportMode.EXPORT_ALL ? this.data.viewParam.q : undefined,
              fields: view.displayedColumns.map(c => c.field2.field),
              expand
            })
          );
          this.dialogRef.close();
        })
      )
      .subscribe();
  }
}
