<div class="container-fluid mb-3 drag-boundary">
  <div class="row">
    <div class="col-12">
      <h1 mat-dialog-title>Add Risk Class</h1>
    </div>
  </div>
  <form [formGroup]="form">
    <div class="row pt-2 mb-4">
      <div class="col-12">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Legislation</mat-label>
          <mat-select [formControlName]="fieldNames.LEGISLATION">
            <mat-option *ngFor="let legislation of (legislations | async)?.results" [value]="legislation.id">
              {{ legislation | legislationOptionFormat }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-12">
        <mat-progress-bar mode="indeterminate" *ngIf="form.pending"></mat-progress-bar>
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Risk Class</mat-label>
          <mat-select [formControlName]="fieldNames.RISK_CLASS">
            <mat-option *ngFor="let riskClass of (riskClasses | async)?.results" [value]="riskClass.id">
              {{ riskClass | riskClassOptionFormat : true }}
            </mat-option>
          </mat-select>
          <mat-hint *ngIf="!form.get(fieldNames.RISK_CLASS).value">{{ availableRiskClassesLabel | async }}</mat-hint>
          <mat-error *ngIf="form.get(fieldNames.RISK_CLASS).hasError('duplicate')">This risk class is already present on this item.</mat-error>
          <mat-error *ngIf="form.get(fieldNames.RISK_CLASS).hasError('legislationAlreadyClassified')">This item already has a risk class in the same legislation.</mat-error>
        </mat-form-field>
      </div>
    </div>
  </form>

  <mat-dialog-actions class="p-0 justify-content-end">
    <button mat-button [attr.e2e-id]="'submit-button'" color="primary" (click)="onSubmit()" [disabled]="form.invalid || form.pending">Add Risk Class</button>
    <button mat-button [attr.e2e-id]="'close-button'" mat-dialog-close>Cancel</button>
  </mat-dialog-actions>
</div>
