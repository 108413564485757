import { Pipe, PipeTransform } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { defaultPaginationOption, FilterOperatorLabels } from '@rims/lib';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppState } from '../../store/store.state';
import { ViewQueryParam } from '../components/rims-table/rims-table-query-params';

@Pipe({
  name: 'viewParam',
  standalone: true
})
export class ViewParamPipe implements PipeTransform {
  constructor(private readonly store: Store<AppState>) {}

  transform(
    param: ViewQueryParam,
    viewId: number,
    mode: 'page' | 'pageSize' | 'orderBy' | 'filter' | 'query'
  ): Observable<string> {
    return this.store.pipe(
      select(state => state.metadata.views[viewId]),
      map(view => {
        switch (mode) {
          case 'page':
            return `${(param.i || defaultPaginationOption.page) + 1}`;
          case 'pageSize':
            return `${param.s || defaultPaginationOption.limit}`;
          case 'query':
            return param.q;
          case 'orderBy': {
            const column = view.columns.find(col => col.id === Number(param.o));
            return `${column.field2.displayName} (${(param.d || 'asc') === 'asc' ? 'Ascending' : 'Descending'})`;
          }
          case 'filter': {
            return param.f
              .map(
                filter =>
                  `${filter.fieldLabel || filter.fieldName} ${
                    FilterOperatorLabels.get(filter.operator) || filter.operator
                  } ${filter.valueLabel || filter.value}`
              )
              .join(', ');
          }
          default:
            return `Can not visualize "${mode}". Please contact your administrator.`;
        }
      })
    );
  }
}
