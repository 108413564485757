<mat-progress-bar [value]="animationProgress"></mat-progress-bar>
<strong class="d-flex align-items-center justify-content-between mb-1" [class.mt-n2]="message">
  <span class="d-flex">
    <mat-icon class="me-2">warning</mat-icon>
    {{ data.error.name }}
  </span>
  <button mat-icon-button (click)="ref.dismiss()">
    <mat-icon>close</mat-icon>
  </button>
</strong>
<span [innerHTML]="message"></span>
