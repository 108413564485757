import { Injectable } from '@angular/core';
import { ApplicationTier } from '@rims/lib';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { map, Observable, of } from 'rxjs';
import { environment } from '../../../../../environments/environment';

interface IdentityClaims {
  /**
   * The 422-username in case of B. Braun employee
   */
  mailnickname: string;
  sub: string;
  aud: string;
  iss: string;
  iat: number;
  exp: number;
  email: string;
  name: string;
  preferred_username: string;
  oid: string;
  nonce: string;
}

@Injectable()
export class AuthService {
  constructor(private readonly oAuthService: OidcSecurityService) {}

  get userId(): Observable<string> {
    if (environment.tier === ApplicationTier.E2E) {
      return of(environment.userId);
    }

    return this.oAuthService.getPayloadFromIdToken().pipe(
      map((claims: IdentityClaims) => {
        if (!claims) {
          return null;
        }
        return claims.mailnickname.toLowerCase();
      })
    );
  }
}
