import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'legislationOptionFormat',
  standalone: true
})
export class LegislationOptionFormatPipe implements PipeTransform {
  transform(legislation: any): string {
    let str = `${legislation.name}`;
    const shortName = legislation.shortName;
    if (shortName) {
      str += ` (${shortName})`;
    }
    return str;
  }
}
