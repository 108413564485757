import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { Component, inject, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  UntypedFormControl,
  Validators
} from '@angular/forms';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyOptionModule } from '@angular/material/legacy-core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
  MatLegacyDialogModule
} from '@angular/material/legacy-dialog';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacySelectModule } from '@angular/material/legacy-select';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import { Store } from '@ngrx/store';
import { ProductGroupResponsibility } from '@rims/database';
import { capitalize, EqualsFilter, InFilter, NotEqualsFilter, SpptNames } from '@rims/lib';
import pluralize from 'pluralize-esm';
import { Observable, of, Subject, timer } from 'rxjs';
import { distinctUntilChanged, map, startWith, take, takeUntil, withLatestFrom } from 'rxjs/operators';
import { CompanyOptionFormatPipe } from 'src/app/modules/shared/pipes/company-option-format.pipe';
import { AppState } from 'src/app/modules/store/store.state';
import {
  deleteProductGroupResponsibilities,
  deleteProductGroupResponsibilitiesSuccess,
  SystemProcedurePackType
} from '../../../product-groups/store/product-groups.actions';
import type { CloseAddDialogPayload, OpenAddDialogPayload } from '../../../store/shared/shared.actions';
import { closeAddDialog } from '../../../store/shared/shared.actions';
import { NamePipe } from '../../pipes/name.pipe';
import { DataService } from '../../services/data/data.service';
import { DuplicateValidator } from '../../validators/duplicate.validator';
import { RimsAutocompleteComponent } from '../rims-autocomplete/rims-autocomplete.component';

enum FieldNames {
  DROPDOWN = 'DROPDOWN',
  QUERY = 'QUERY',
  SELECTED_VALUES = 'SELECTED_VALUES',
  CONFIRM = 'CONFIRM',
  CONFIRM_SPPT = 'CONFIRM_SPPT',
  DROPDOWN_SPPT = 'DROPDOWN_SPPT',
  CONFIRM_OVERWRITE_ITEMS = 'CONFIRM_OVERWRITE_ITEMS',
  CONFIRM_REMOVE_RESPONSIBILITIES = 'CONFIRM_REMOVE_RESPONSIBILITIES'
}

@Component({
  selector: 'rims-add-dialog',
  templateUrl: './add-dialog.component.html',
  styleUrls: ['./add-dialog.component.scss'],
  standalone: true,
  imports: [
    MatLegacyDialogModule,
    NgIf,
    FormsModule,
    ReactiveFormsModule,
    MatLegacyFormFieldModule,
    MatLegacySelectModule,
    NgFor,
    MatLegacyOptionModule,
    RimsAutocompleteComponent,
    MatLegacyCheckboxModule,
    MatLegacyTooltipModule,
    MatLegacyButtonModule,
    AsyncPipe,
    NamePipe
  ]
})
export class AddDialogComponent implements OnInit {
  readonly formatPipe = new CompanyOptionFormatPipe();
  readonly fieldNames = FieldNames;
  readonly data: OpenAddDialogPayload = inject(MAT_DIALOG_DATA);
  readonly name = this.data.displayName ?? this.data.entityName;
  readonly relation = this.data.relation ?? this.data.entityName + '2';
  readonly identifier = 'id';
  readonly actionTxt = this.data.transfer ? 'transfer' : 'add';
  readonly forbiddenChildren =
    this.data.key === 'product_group_children' && this.data.allowedChildGroupTypeIds.length === 0;
  readonly target = this.data.target ?? 'id';
  readonly searchLabel = this.data.searchLabel ?? `Search by ${capitalize(this.name)}`;
  readonly placeholder = this.data.placeholder ?? `New ${this.name.toLowerCase()}...`;
  readonly notFoundHint = this.data.notFoundHint ?? `No ${this.name} found. Try again with another query.`;
  readonly duplicateHint = this.data.duplicateHint ?? `${this.name} already assigned`;

  sppTypes: SystemProcedurePackType[];

  // we display the dropdown values differently for departments
  readonly reverse = this.data.dropdown === 'departments';

  form: FormGroup;
  dropdownValues: Observable<any[]>;
  amountMultiple: number;
  currentName: string;
  actionPrefix: string;
  selected: any;
  skipMismatchValidation = false;
  mismatchItems: Observable<string[]>;
  confirmOverwriteItems: Observable<boolean>;
  confirmOverwriteItemsTxt: Observable<string>;
  overwriteItemsPermission = false;
  forbiddenTransfer = this.data.transfer && (this.data.toId as ProductGroupResponsibility[])?.length < 1;
  restrictedTransfer = !this.forbiddenTransfer && this.data.restrictedResponsibilities?.length > 0;

  @ViewChild('autocomplete') autocomplete: RimsAutocompleteComponent;

  private selectedResponsibilityTxt = '';

  private destroy$ = new Subject<boolean>();

  constructor(
    public readonly dialogRef: MatDialogRef<AddDialogComponent>,
    private readonly dataService: DataService,
    private readonly store: Store<AppState>,
    private readonly fb: FormBuilder,
    private readonly validator: DuplicateValidator
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      autocomplete: this.fb.group({
        [FieldNames.QUERY]: new UntypedFormControl({ value: null, disabled: !!this.data.dropdown }),
        [FieldNames.SELECTED_VALUES]: new UntypedFormControl([], [Validators.required])
      })
    });

    if (this.data.transfer) {
      const multiple = (this.data.toId as ProductGroupResponsibility[]).length > 1;
      this.selectedResponsibilityTxt = `the selected ${multiple ? 'responsibilities' : 'responsibility'}`;
      this.form.addControl(FieldNames.CONFIRM_REMOVE_RESPONSIBILITIES, new UntypedFormControl(false));
    }

    if (this.data.dropdown) {
      this.form.addControl(FieldNames.DROPDOWN, new UntypedFormControl(null, Validators.required));
      const url = this.data.dropdown.replace(/\s/g, '');
      this.dropdownValues = this.dataService.getAll(url).pipe(map(page => page.results));
    }

    if (this.data.confirm) {
      this.form.addControl(FieldNames.CONFIRM, new UntypedFormControl(false, Validators.requiredTrue));
    }

    // we dont want to check for duplicates if we target a reference (e.g. item/container)
    // because e.g. a group may already list a specific nomenclature while an item in this group doesn't
    if (!this.data.referenceName) {
      this.form
        .get('autocomplete')
        .get(FieldNames.SELECTED_VALUES)
        .addAsyncValidators(this.duplicateValidator.bind(this));
    }

    if (this.data.key === 'product_group_item' && this.data.isABudi) {
      this.form.addControl(FieldNames.CONFIRM_OVERWRITE_ITEMS, new UntypedFormControl(false));
      this.pollMismatchItems();
      this.setOverwriteItemsPermission();
      this.processOverwriteItems();
      this.getConfirmOverwriteItems();
      this.getConfirmOverwriteItemsTxt();
    }

    if (this.data.key === 'product_group_actor' && this.data.isABudi && !this.data.sppt) {
      this.dataService
        .getAll('systemProcedurePackTypes', undefined, undefined, Number.MAX_SAFE_INTEGER)
        .pipe(take(1))
        .subscribe(({ results }) => {
          this.sppTypes = results;
        });

      this.form.addControl(FieldNames.CONFIRM_SPPT, new UntypedFormControl(false, Validators.requiredTrue));
      this.form.addControl(FieldNames.DROPDOWN_SPPT, new FormControl<SpptNames>(null));
    }

    this.processValueChanges();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  getTitle() {
    return `${capitalize(this.actionTxt)} ${capitalize(this.currentName)}`;
  }

  getDropdownTitle() {
    const name = pluralize.singular(this.data.dropdown);
    return capitalize(name);
  }

  getAddToTxt() {
    const prefix = this.amountMultiple ? `These` : `This`;
    return `${prefix} ${this.currentName} will be added${this.getToTxt()}.`;
  }

  getConfirmTxt() {
    if (this.data.transfer) {
      const toTxt = this.amountMultiple ? `these ${this.amountMultiple} users` : `this user`;
      return `I confirm that I want to <i>transfer</i> ${this.selectedResponsibilityTxt} to ${toTxt}.`;
    }

    const amountTxt = this.amountMultiple
      ? `these ${this.amountMultiple} ${this.currentName}`
      : `this ${this.currentName}`;

    return `I confirm that I want to add ${amountTxt}${this.getToTxt()}.`;
  }

  getConfirmSpptTxt() {
    const confirmTxt = `I confirm that I want to set <i>System Procedure Pack Type</i>`;
    const value = this.form.get(FieldNames.DROPDOWN_SPPT).value?.name;

    return value ? `${confirmTxt} to <b>${value}</b>` : confirmTxt;
  }

  getConfirmOverwriteItemsTxt() {
    this.confirmOverwriteItemsTxt = this.mismatchItems.pipe(
      map(items => {
        const name = items?.length > 1 ? 'items' : 'item';
        return `I confirm that I want to overwrite the properties of <b class="optional">${items?.length}</b> ${name} with the properties of this BUDI <i>(optional)</i>`;
      }),
      takeUntil(this.destroy$)
    );
  }

  getConfirmRemoveTxt() {
    const userName = `${this.data.transferUser.firstName} ${this.data.transferUser.lastName}`;
    return `I confirm that I want to <i>remove</i> ${this.selectedResponsibilityTxt} from ${userName} <i>(optional)</i>`;
  }

  getConfirmOverwriteItems() {
    this.confirmOverwriteItems = this.mismatchItems.pipe(
      map(mismatchItems => {
        return this.data.isABudi && mismatchItems?.length > 0;
      })
    );
  }

  getActionTxt() {
    if (!this.data.multiple) {
      return `${capitalize(this.actionTxt)} ${capitalize(this.name)}`;
    }

    return `${this.actionPrefix} ${capitalize(this.currentName)}`;
  }

  /**
   * When the dropdown value changes, we trigger valueChanges on the query control with the current value.
   * This triggers a new http request with updated filters (different dropdown value).
   */
  onSelectionChange() {
    this.autocomplete.clear();
    this.form.get(FieldNames.CONFIRM)?.reset();
    const queryCtrl = this.form.get('autocomplete').get(FieldNames.QUERY);

    if (this.form.get(FieldNames.DROPDOWN).valid && queryCtrl.disabled) {
      queryCtrl.enable({ emitEvent: false });
    }
  }

  processOverwriteItems() {
    this.form
      .get(FieldNames.CONFIRM_OVERWRITE_ITEMS)
      .valueChanges.pipe(withLatestFrom(this.mismatchItems), takeUntil(this.destroy$))
      .subscribe(([confirm, items]) => {
        this.skipMismatchValidation = confirm;

        if (items?.length && confirm != null) {
          this.autocomplete.paste(null, items, true);
        }
      });
  }

  /**
   * When the selected values change, we reset the confirm checkbox and update name, amount and the spptControls
   */
  processValueChanges() {
    this.form
      .get('autocomplete')
      .get(FieldNames.SELECTED_VALUES)
      .valueChanges.pipe(startWith([], takeUntil(this.destroy$)))
      .subscribe(() => {
        this.form.get(FieldNames.CONFIRM)?.reset();

        const values = this.form.get('autocomplete').get(FieldNames.SELECTED_VALUES)?.value || [];
        this.amountMultiple = values?.length > 1 ? values.length : '';

        if (values.length > 0) {
          this.form.get(FieldNames.CONFIRM)?.enable();
          this.form.get(FieldNames.CONFIRM_REMOVE_RESPONSIBILITIES)?.enable();
        } else {
          this.form.get(FieldNames.CONFIRM)?.disable();
          this.form.get(FieldNames.CONFIRM_REMOVE_RESPONSIBILITIES)?.disable();
        }

        let referenceLength: number;

        if (this.data.transfer) {
          // in case of transfer we want to display the amount of selected responsibilities
          referenceLength = (this.data.toId as ProductGroupResponsibility[])?.length || 0;
        } else {
          referenceLength = values?.length || 0;
        }

        if (referenceLength > 1) {
          this.currentName = pluralize(this.name);
          this.actionPrefix = `${capitalize(this.actionTxt)} ${referenceLength}`;
        } else {
          this.currentName = pluralize.singular(this.name);
          this.actionPrefix = `${capitalize(this.actionTxt)}`;
        }

        if (this.data.key === 'product_group_actor' && this.data.isABudi && !this.data.sppt) {
          this.updateSpptControls();
        }
      });
  }

  private updateSpptControls() {
    if (this.isSpptActor()) {
      this.form.get(FieldNames.DROPDOWN_SPPT).reset();
      this.form.get(FieldNames.CONFIRM_SPPT).reset();
      this.form.get(FieldNames.DROPDOWN_SPPT).enable();
      this.form.get(FieldNames.CONFIRM_SPPT).enable();
      if (this.isMatchingType(true)) {
        // pre-select 'Not applicable' as it is the only valid option for an actor of type 'Manufacturer'
        const notApplicable = this.sppTypes.find(type => type.notApplicable);
        this.form.get(this.fieldNames.DROPDOWN_SPPT).setValue(notApplicable);
      }
    } else {
      this.form.get(FieldNames.DROPDOWN_SPPT).disable();
      this.form.get(FieldNames.CONFIRM_SPPT).disable();
    }
  }

  /**
   * This method is called from within the rims-autocomplete component.
   * @returns the filters to be used when querying the entity.
   */
  getFilters() {
    switch (this.data.key) {
      case 'item_nomenclature':
        return [new EqualsFilter('nomenclature_type', this.form.get(FieldNames.DROPDOWN).value)];
      case 'product_group_children':
        return [new InFilter('group_type', this.data.allowedChildGroupTypeIds.join(','))];
      case 'product_group_actor': {
        const sppt = this.data.sppt;
        if (!sppt) return;

        const spptApplicable = sppt.notApplicable === false;
        const forbiddenType = spptApplicable ? 3 : 4;
        return [new NotEqualsFilter('actor_type', forbiddenType)];
      }
      default:
        return [];
    }
  }

  onSubmit() {
    const payload: CloseAddDialogPayload = {
      ...this.data,
      entityIds: this.form.get('autocomplete').value[FieldNames.SELECTED_VALUES]
    };

    if (this.data.key === 'product_group_children') {
      payload.toName = 'parent';
      payload.entityName = 'child';
    }

    if (
      this.data.key === 'product_group_responsibility' ||
      (this.data.key === 'responsibility_product_group' && !this.data.transfer)
    ) {
      payload.department = this.form.get(FieldNames.DROPDOWN)?.value;
    }

    if (this.data.transfer && this.form.get(FieldNames.CONFIRM_REMOVE_RESPONSIBILITIES)?.value) {
      payload.reloadAction = deleteProductGroupResponsibilities;
      payload.reloadActionPayload = { ids: (this.data.toId as ProductGroupResponsibility[]).map(r => r.id) };
      payload.reloadActionSuccess = deleteProductGroupResponsibilitiesSuccess;
    }

    if (this.data.key === 'product_group_actor' && this.data.isABudi && !this.data.sppt) {
      if (this.isSpptActor()) {
        payload.reloadActionPayload = {
          ...payload.reloadActionPayload,
          sppt: this.form.get(FieldNames.DROPDOWN_SPPT).value,
          entityId: payload.toId
        };
      } else {
        payload.reloadAction = null;
      }
    }

    if (this.data.key === 'product_group_item' && this.form.get(FieldNames.CONFIRM_OVERWRITE_ITEMS)?.value) {
      payload.mismatch = this.getMismatchItems();
    }

    this.store.dispatch(closeAddDialog(payload));
    this.dialogRef.close();
  }

  isSpptActor() {
    const query: string = this.form.get('autocomplete')?.value[FieldNames.QUERY];
    return query?.startsWith('Manufacturer') || query?.startsWith('System');
  }

  /**
   *
   * @returns true when
   *  - sppt is not applicable and actor is of type manufacturer
   *  - sppt is applicable (e.g. 'System') and actor is of type System/ProcedurePack
   */
  isMatchingType(notApplicable: boolean) {
    const query: string = this.form.get('autocomplete').value[FieldNames.QUERY];
    const isManufacturer = query?.startsWith('Manufacturer');

    return isManufacturer === notApplicable;
  }

  onSelected(selected: any) {
    this.selected = selected;
  }

  setOverwriteItemsPermission() {
    this.store
      .pipe(
        take(1),
        map(state => ({
          userState: state.user,
          entity: Object.keys(state.metadata.entities)
            .map(id => state.metadata.entities[id])
            .find(en => en.name === 'item')
        }))
      )
      .subscribe(({ userState, entity }) => {
        const role = userState.user.role;
        this.overwriteItemsPermission = role.permissionLevel >= entity.permissions['updatePermissionLevel'];
      });
  }

  /**
   * start polling the mismatch items array (every 100ms)
   */
  private pollMismatchItems() {
    this.mismatchItems = timer(0, 100).pipe(
      map(() => this.getMismatchItems()),
      distinctUntilChanged((prev, curr) => {
        return JSON.stringify(prev) === JSON.stringify(curr);
      }),
      takeUntil(this.destroy$)
    );
  }

  /**
   * @returns a list of itemNumbers of items that have different properties than the BUDI
   */
  private getMismatchItems(): string[] {
    return this.autocomplete.chips.filter(chip => !!chip.mismatch).map(chip => chip.error ?? chip.itemNumber);
  }

  private getToTxt() {
    if (!this.data.toName) return '';
    let toTxt = ` to this ${this.data.toName}`;
    if (this.data.referenceName) {
      const referenceAmount = this.data.referenceIds?.length;
      const referenceName =
        referenceAmount === 1 ? pluralize.singular(this.data.referenceName) : pluralize(this.data.referenceName);
      toTxt = referenceAmount
        ? ` to ${referenceAmount} selected ${referenceName} in this ${this.data.toName}`
        : ` to all ${referenceName} in this ${this.data.toName}`;
    }
    return toTxt;
  }

  private duplicateValidator(control: UntypedFormControl) {
    const value = control.value;
    const id = this.data.toId;
    if (this.data.transfer || Array.isArray(id)) return of(null);

    switch (this.data.key) {
      case 'product_group_item': {
        const selectedItems = value
          .filter(number => {
            return !!this.selected[number];
          })
          .map(number => this.selected[number]);

        return this.validator.productGroupItemValidator(
          selectedItems,
          id,
          this.duplicateHint,
          this.data.budiInfo,
          this.skipMismatchValidation,
          this.overwriteItemsPermission,
          this.data.isEbudi
        );
      }
      case 'product_group_actor': {
        const filters = [new InFilter('actor', value.join(',')), new EqualsFilter('product_group', id)];
        return this.validator.duplicateValidator('productgroupactors', filters, 'id');
      }
      case 'product_group_responsibility': {
        const filters = [
          new InFilter('user', value.join(',')),
          new EqualsFilter('department', this.form.get(FieldNames.DROPDOWN).value),
          new EqualsFilter('product_group', id)
        ];
        return this.validator.duplicateValidator('productgroupresponsibilities', filters, 'user');
      }
      case 'responsibility_product_group': {
        const filters = [
          new EqualsFilter('user', id),
          new EqualsFilter('department', this.form.get(FieldNames.DROPDOWN).value),
          new InFilter('productGroup', value.join(','))
        ];
        return this.validator.duplicateValidator('responsibilityproductgroups', filters, 'productGroup');
      }
      case 'product_group_children': {
        const filters = [new InFilter('child', value.join(',')), new EqualsFilter('parent', id)];
        return this.validator.duplicateValidator('productgroupchildren', filters, 'child');
      }
      case 'item_nomenclature': {
        const filters = [new InFilter('nomenclature', value.join(',')), new EqualsFilter('item', id)];
        return this.validator.duplicateValidator('itemnomenclatures', filters, 'nomenclature');
      }
      case 'item_document': {
        const filters = [new InFilter('document', value.join(',')), new EqualsFilter('item', id)];
        return this.validator.duplicateValidator('itemdocuments', filters, 'document');
      }
      case 'item_production_site': {
        const filters = [new InFilter('company', value.join(',')), new EqualsFilter('item', id)];
        return this.validator.duplicateValidator('itemproductionsites', filters, 'company');
      }
      case 'container_production_site': {
        const filters = [new InFilter('company', value.join(',')), new EqualsFilter('container', id)];
        return this.validator.duplicateValidator('containerproductionsites', filters, 'company');
      }
      default:
        return of(null);
    }
  }
}
