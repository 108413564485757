import { routerReducer } from '@ngrx/router-store';
import { metaReducer } from '../meta/store/meta.reducer';
import { userReducer } from '../store/user/user.reducer';
import { dataReducer } from './data/data.reducer';
import { metadataReducer } from './metadata/metadata.reducer';

export function reducers() {
  return {
    router: routerReducer,
    data: dataReducer,
    user: userReducer,
    metadata: metadataReducer,
    meta: metaReducer
  };
}
