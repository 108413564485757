import { createReducer, on } from '@ngrx/store';
import { loadApplicationMetadata, loadApplicationMetadataSuccess } from './meta.actions';
import { META_INITIAL_STATE } from './meta.state';

export const metaReducer = createReducer(
  META_INITIAL_STATE,
  on(loadApplicationMetadata, state => ({
    ...state,
    loading: true
  })),
  on(loadApplicationMetadataSuccess, (state, payload) => ({
    ...state,
    ...payload,
    loading: false
  }))
);
