<div class="container-fluid mb-3 add-containers-to-product-group-dialog" *ngIf="{ containers: containers | async } as observable">
  <div class="row">
    <div class="col-12 mb-4">
      <h1 mat-dialog-title class="m-0">Add Containers</h1>
      <span class="color-grey d-block">You can add one or more containers. Start by narrowing down the desired container type.</span>
      <span class="color-grey" *ngIf="data.allowedContainerTypes !== allowedContainerTypes.BOTH">
        Because of the existing containers in this solution, you can only add
        <ng-container *ngIf="data.allowedContainerTypes === allowedContainerTypes.ONLY_NON_ADVANCED">non-</ng-container>
        advanced containers.
      </span>
    </div>
  </div>

  <div *ngIf="observable.containers?.resultsSize" class="row" [class.d-lg-none]="!filterForm.classList.contains('d-none')">
    <div class="col-12">
      <mat-checkbox (change)="filterForm.classList.toggle('d-none')">
        {{ filterForm.classList.contains('d-none') ? 'Show' : 'Hide' }}
        filters
      </mat-checkbox>
    </div>
  </div>

  <form [formGroup]="form" class="w-100" #filterForm>
    <div class="row">
      <div class="col-12">
        <mat-progress-bar mode="indeterminate" *ngIf="form.pending || loadingOptions"></mat-progress-bar>
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Container Type</mat-label>
          <input matInput placeholder="Find a container type..." [formControlName]="formControlNames.CONTAINER_TYPE" [matAutocomplete]="containerTypeAutocomplete" />
          <mat-autocomplete #containerTypeAutocomplete="matAutocomplete" [displayWith]="containerTypeDisplayFunction">
            <mat-option *ngFor="let containerType of (containerTypes | async)?.results" [value]="containerType">
              {{ containerType | containerTypeFormat }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
      <div class="col-12 col-md-2">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Size</mat-label>
          <mat-select [formControlName]="formControlNames.SIZE_COMPARISON_MODE">
            <mat-option [value]="sizeComparisonMode.EQUALS">=</mat-option>
            <mat-option [value]="sizeComparisonMode.GREATER_THAN">&gt;</mat-option>
            <mat-option [value]="sizeComparisonMode.LESS_THAN">&lt;</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-12 col-md-4">
        <mat-form-field appearance="fill" class="w-100" floatLabel="always">
          <mat-label>Size</mat-label>
          <input matInput type="number" placeholder="optional" [formControlName]="formControlNames.SIZE" />
          <mat-hint *ngIf="form.get(formControlNames.SIZE).value !== null">
            <ng-container *ngTemplateOutlet="clearInputButton; context: { formField: formControlNames.SIZE }"></ng-container>
          </mat-hint>
        </mat-form-field>
      </div>
      <div class="col-12 col-md-6">
        <mat-form-field appearance="fill" class="w-100" floatLabel="always">
          <mat-label>Measurement Unit</mat-label>
          <mat-select [formControlName]="formControlNames.UNIT" placeholder="optional">
            <mat-option *ngFor="let unit of (substanceUnits | async)?.results" [value]="unit">
              {{ unit.shortName }}
            </mat-option>
          </mat-select>
          <mat-hint *ngIf="form.get(formControlNames.UNIT).value !== null">
            <ng-container *ngTemplateOutlet="clearInputButton; context: { formField: formControlNames.UNIT }"></ng-container>
          </mat-hint>
        </mat-form-field>
      </div>
    </div>
    <div class="row" *ngIf="form.value[formControlNames.ALLOW_ADVANCED_FILTERS]">
      <div class="col-12">
        <mat-checkbox [formControlName]="formControlNames.ENABLE_ADVANCED_FILTERS">Filter by advanced container properties</mat-checkbox>
      </div>
      <div class="col-12 col-md-4" *ngIf="form.value[formControlNames.ENABLE_ADVANCED_FILTERS]">
        <mat-form-field appearance="fill" class="w-100" floatLabel="always">
          <mat-label>Cap</mat-label>
          <mat-select [formControlName]="formControlNames.CAP" placeholder="optional">
            <mat-option>
              <span class="font-italic">- none -</span>
            </mat-option>
            <mat-option *ngFor="let cap of (containerCaps | async)?.results" [value]="cap">
              {{ cap | name : undefined : true }}
            </mat-option>
          </mat-select>
          <mat-hint *ngIf="form.get(formControlNames.CAP).value !== null">
            <ng-container *ngTemplateOutlet="clearInputButton; context: { formField: formControlNames.CAP }"></ng-container>
          </mat-hint>
        </mat-form-field>
      </div>
      <div class="col-12 col-md-4" *ngIf="form.value[formControlNames.ENABLE_ADVANCED_FILTERS]">
        <mat-form-field appearance="fill" class="w-100" floatLabel="always">
          <mat-label>Shape</mat-label>
          <mat-select [formControlName]="formControlNames.SHAPE" placeholder="optional">
            <mat-option>
              <span class="font-italic">- none -</span>
            </mat-option>
            <mat-option *ngFor="let shape of (containerShapes | async)?.results" [value]="shape">
              {{ shape | name : undefined : true }}
            </mat-option>
          </mat-select>
          <mat-hint *ngIf="form.get(formControlNames.SHAPE).value !== null">
            <ng-container *ngTemplateOutlet="clearInputButton; context: { formField: formControlNames.SHAPE }"></ng-container>
          </mat-hint>
        </mat-form-field>
      </div>
      <div class="col-12 col-md-4" *ngIf="form.value[formControlNames.ENABLE_ADVANCED_FILTERS]">
        <mat-form-field appearance="fill" class="w-100" floatLabel="always">
          <mat-label>Color</mat-label>
          <mat-select [formControlName]="formControlNames.COLOR" placeholder="optional">
            <mat-option>
              <span class="font-italic">- none -</span>
            </mat-option>
            <mat-option *ngFor="let color of (containerColors | async)?.results" [value]="color">
              {{ color | name : undefined : true }}
            </mat-option>
          </mat-select>
          <mat-hint *ngIf="form.get(formControlNames.COLOR).value !== null">
            <ng-container *ngTemplateOutlet="clearInputButton; context: { formField: formControlNames.COLOR }"></ng-container>
          </mat-hint>
        </mat-form-field>
      </div>
    </div>
  </form>
  <form [formGroup]="containerSelectionForm" class="w-100">
    <div class="row">
      <div class="col-12">
        <ng-container *ngIf="observable.containers?.resultsSize; else noContainers">
          <mat-selection-list dense [formControlName]="formControlNames.SELECTED_CONTAINERS">
            <mat-list-option *ngFor="let container of observable.containers.results" [value]="container" color="primary" checkboxPosition="before">
              {{ container | containerOptionFormat }}
            </mat-list-option>
          </mat-selection-list>
        </ng-container>
        <ng-template #noContainers>
          <span>No containers match the filter criteria.</span>
        </ng-template>
      </div>
    </div>
  </form>

  <mat-dialog-actions class="p-0 justify-content-end">
    <ng-container *ngIf="containerSelectionForm.get(formControlNames.SELECTED_CONTAINERS)?.value?.length as selectedAmount; else noContainersSelected">
      <button mat-button color="primary" (click)="submit()" [disabled]="containerSelectionForm.invalid" [ngSwitch]="selectedAmount">
        <ng-container *ngSwitchCase="0">
          <ng-container *ngTemplateOutlet="noContainersSelectedMessage"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="1">Add one container</ng-container>
        <ng-container *ngSwitchDefault>
          <ng-container *ngIf="form.valid; else invalidCriteria">Add {{ selectedAmount }} containers</ng-container>
          <ng-template #invalidCriteria>Use the filters to display a list of available containers</ng-template>
        </ng-container>
      </button>
    </ng-container>
    <ng-template #noContainersSelected>
      <button mat-button disabled>
        <ng-container *ngTemplateOutlet="noContainersSelectedMessage"></ng-container>
      </button>
    </ng-template>
    <button mat-button mat-dialog-close class="ms-2">Cancel</button>
  </mat-dialog-actions>
</div>

<ng-template #noContainersSelectedMessage>Select one or more containers</ng-template>

<ng-template #clearInputButton let-formField="formField">
  <span class="clickable" role="button" (click)="clearField(formField)">Clear</span>
</ng-template>
