/// <reference types="../node_modules/@types/ineum/index.d.ts" />

import { enableProdMode, ErrorHandler, importProvidersFrom } from '@angular/core';

import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptors, withInterceptorsFromDi } from '@angular/common/http';
import { DefaultValueAccessor } from '@angular/forms';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { bootstrapApplication } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  AbstractSecurityStorage,
  authInterceptor,
  OpenIdConfiguration,
  provideAuth,
  withAppInitializerAuthCheck
} from 'angular-auth-oidc-client';
import { AppRoutingModule } from './app/app-routing.module';
import { AppComponent } from './app/app.component';
import { APP_CONFIG, appConfig } from './app/app.constants';
import { RimsErrorHandler } from './app/error-handler';
import { CancelInterceptor } from './app/modules/shared/interceptors/cancel.interceptor';
import { LoadingInterceptor } from './app/modules/shared/interceptors/loading.interceptor';
import { StorageService } from './app/modules/shared/services/auth/storage.service';
import { RimsStoreModule } from './app/modules/store/store.module';
import { environment } from './environments/environment';

const authConfig: OpenIdConfiguration = {
  authority: `${environment.oauth2.issuer}/v2.0`,
  authWellknownEndpointUrl: `${environment.oauth2.issuer}/v2.0/.well-known/openid-configuration`,
  redirectUrl: environment.oauth2.redirectUri,
  clientId: environment.oauth2.clientId,
  scope: environment.oauth2.scope,
  responseType: 'code',
  silentRenew: true,
  maxIdTokenIatOffsetAllowedInSeconds: 600,
  autoUserInfo: false,
  useRefreshToken: true,
  ignoreNonceAfterRefresh: true,
  triggerRefreshWhenIdTokenExpired: false,
  secureRoutes: [environment.oauth2.issuer, environment.backendUrl]
};

const modules = [BrowserAnimationsModule, AppRoutingModule, MatSnackBarModule, RimsStoreModule];

const providers = [
  {
    provide: APP_CONFIG,
    useValue: appConfig
  },
  {
    provide: ErrorHandler,
    useClass: RimsErrorHandler
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: LoadingInterceptor,
    multi: true
  },
  {
    multi: true,
    provide: HTTP_INTERCEPTORS,
    useClass: CancelInterceptor
  },
  provideHttpClient(withInterceptorsFromDi(), withInterceptors([authInterceptor()])),
  provideAuth(
    {
      config: authConfig
    },
    withAppInitializerAuthCheck()
  ),
  { provide: AbstractSecurityStorage, useClass: StorageService }
];

/**
 * trims every text input before any form specific logic applies
 */
function trimInputOnChange() {
  const original = DefaultValueAccessor.prototype.registerOnChange;
  DefaultValueAccessor.prototype.registerOnChange = function (fn) {
    return original.call(this, value => {
      const trimmed = typeof value === 'string' ? value.trim() : value;
      return fn(trimmed);
    });
  };
}

if (environment.production) {
  enableProdMode();
}

trimInputOnChange();

bootstrapApplication(AppComponent, {
  providers: [importProvidersFrom(...modules), ...providers]
}).catch(err => console.error(err));
