import { createAction, props } from '@ngrx/store';
import { MetaState } from './meta.state';

const PREFIX = '[Meta]';

export const loadApplicationMetadata = createAction(`${PREFIX} Load Application Metadata`);
export const loadApplicationMetadataSuccess = createAction(
  `${PREFIX} Load Application Metadata Success`,
  props<Pick<MetaState, 'appVersion' | 'buildTime' | 'commitHash'>>()
);
