<div class="container-fluid mb-3 drag-boundary">
  <div class="row">
    <div class="col-12 mb-4">
      <h1 mat-dialog-title class="m-0">Change BUDI Lifecycle</h1>
      <span class="d-block color-grey">As soon as the BUDI lifecycle is set to "Ready for EUDAMED",</span>
      <span class="color-grey">you will no longer be able to change it in RIMS.</span>
    </div>
  </div>
  <div class="row pt-2 mb-4">
    <div class="col-12">
      <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <mat-checkbox [formControlName]="fieldNames.CONFIRM">I confirm that I want to change the BUDI lifecycle to "Ready for EUDAMED"</mat-checkbox>
        <button type="submit" hidden #button></button>
      </form>
    </div>
  </div>

  <mat-dialog-actions class="p-0 justify-content-end">
    <button mat-button [attr.e2e-id]="'submit-button'" color="primary" (click)="button.click()" [disabled]="form.invalid">Submit</button>
    <button mat-button [attr.e2e-id]="'close-button'" mat-dialog-close>Cancel</button>
  </mat-dialog-actions>
</div>
