import { AsyncPipe, NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { MatLegacyProgressBarModule } from '@angular/material/legacy-progress-bar';
import { RouterOutlet } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { ApplicationTier } from '@rims/lib';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { loadApplicationMetadata } from './modules/meta/store/meta.actions';
import { FooterComponent } from './modules/shared/components/footer/footer.component';
import { User } from './modules/shared/models/user/user.model';
import { AppState } from './modules/store/store.state';
import { login } from './modules/store/user/user.actions';
import { isInvalidLoginAttempt, loggedInUser } from './modules/store/user/user.state';

@Component({
  selector: 'rims-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [NgIf, RouterOutlet, FooterComponent, MatLegacyProgressBarModule, AsyncPipe]
})
export class AppComponent {
  tier = environment.tier;
  showTier = this.tier !== ApplicationTier.PRD;

  loggedInUser: Observable<User>;
  isInvalidLoginAttempt: Observable<boolean>;
  alreadyLoggedIn = false;

  constructor(private readonly store: Store<AppState>, private readonly auth: OidcSecurityService) {
    this.store.dispatch(loadApplicationMetadata());
    this.loggedInUser = this.store.pipe(select(loggedInUser));
    this.isInvalidLoginAttempt = this.store.pipe(select(isInvalidLoginAttempt));
    this.auth.isAuthenticated$.subscribe(({ isAuthenticated }) => {
      if (isAuthenticated && !this.alreadyLoggedIn) {
        this.store.dispatch(login());
        this.alreadyLoggedIn = true;
      }
    });
  }
}
