import { CdkDrag, CdkDragDrop, CdkDropList } from '@angular/cdk/drag-drop';
import { AsyncPipe, NgFor, NgIf, NgSwitch, NgSwitchCase } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
  MatLegacyDialogModule
} from '@angular/material/legacy-dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import {
  closeColumnPreferencesDialog,
  moveColumn,
  resetColumnPreferences,
  saveColumns
} from '../../../store/metadata/metadata.actions';
import { ColumnPreferencesDialogState } from '../../../store/metadata/metadata.state';
import { AppState } from '../../../store/store.state';
import { TableColumn } from '../../models/table/table-column.model';
import { ViewQueryParam } from '../rims-table/rims-table-query-params';

export interface RimsTableColumnSortDialogInput {
  viewId: number;
  fixedColumnIds?: number[];
}

@Component({
  selector: 'rims-table-column-sort-dialog',
  templateUrl: './rims-table-column-sort-dialog.component.html',
  styleUrls: ['./rims-table-column-sort-dialog.component.scss'],
  standalone: true,
  imports: [
    MatLegacyDialogModule,
    NgIf,
    CdkDropList,
    NgFor,
    CdkDrag,
    NgSwitch,
    NgSwitchCase,
    MatIconModule,
    MatLegacyButtonModule,
    AsyncPipe
  ]
})
export class RimsTableColumnSortDialogComponent implements OnInit {
  config: Observable<ColumnPreferencesDialogState>;

  readonly data: RimsTableColumnSortDialogInput = inject(MAT_DIALOG_DATA);

  constructor(
    private readonly store: Store<AppState>,
    public readonly dialogRef: MatDialogRef<RimsTableColumnSortDialogComponent>,
    private readonly route: ActivatedRoute,
    private readonly router: Router
  ) {}

  ngOnInit() {
    this.config = this.store.pipe(select(state => state.metadata.columnPreferencesDialog));
  }

  reset() {
    this.store.dispatch(resetColumnPreferences({ viewId: this.data.viewId }));
    this.dialogRef.close();
  }

  save() {
    this.store.dispatch(saveColumns());
    this.dialogRef.close();
  }

  close() {
    this.store.dispatch(closeColumnPreferencesDialog());
    this.dialogRef.close();
  }

  enterPredicate(drag: CdkDrag, drop: CdkDropList): boolean {
    // We only want to allow columns to be hidden with showalways === false
    if (drop.id.includes('available')) {
      const showAlways = drag.element.nativeElement.getAttribute('rims-column-show-always') as 'true' | 'false';
      return showAlways === 'false';
    }

    return true;
  }

  drop(event: CdkDragDrop<TableColumn>) {
    this.store.dispatch(
      moveColumn({
        source: event.previousContainer.id.includes('available') ? 'available' : 'selected',
        target: event.container.id.includes('available') ? 'available' : 'selected',
        sourceIndex: event.previousIndex,
        targetIndex: event.currentIndex
      })
    );
  }

  removeColumnConfigFromQueryParams() {
    const key = `view_${this.data.viewId}`;
    const rawParam = this.route.snapshot.queryParamMap.get(key);
    const param = ViewQueryParam.decode(rawParam);
    param.c = undefined;
    const queryParams = { [key]: param.encode() };
    this.router.navigate([], {
      queryParams,
      queryParamsHandling: 'merge',
      replaceUrl: true
    });

    this.close();
  }
}
