import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { noop } from '../shared/shared.actions';
import { AppState } from '../store.state';
import { navigateTo, viewAccessNotAllowed, viewNotFound } from './router.actions';

@Injectable()
export class RouterEffects {
  constructor(
    private readonly store: Store<AppState>,
    private readonly actions: Actions,
    private readonly snackBar: MatSnackBar,
    private readonly router: Router,
    private readonly location: Location
  ) {}

  navigateTo = createEffect(() =>
    this.actions.pipe(
      ofType(navigateTo),
      map(payload => {
        this.router.navigateByUrl(payload.routerLink);
        return noop();
      })
    )
  );

  onViewAccessNotAllowed = createEffect(() =>
    this.actions.pipe(
      ofType(viewAccessNotAllowed),
      switchMap(() => {
        this.router.navigate(['home']);
        this.snackBar.open(`❌  You are not allowed to access this view`, null, {
          duration: 4000,
          horizontalPosition: 'right'
        });
        return of(noop());
      })
    )
  );

  onViewNotFound = createEffect(() =>
    this.actions.pipe(
      ofType(viewNotFound),
      switchMap(props => {
        console.log(`View "${props.view}" could not be found.`);

        this.router.navigate([props.redirectUrl ?? 'home']);
        this.snackBar.open(`❌  The requested view was not found`, null, {
          duration: 4000,
          horizontalPosition: 'right'
        });

        return of(noop());
      })
    )
  );
}
