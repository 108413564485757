import { Component, inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCheckboxModule } from '@angular/material/legacy-checkbox';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
  MatLegacyDialogModule
} from '@angular/material/legacy-dialog';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/modules/store/store.state';
import {
  closeChangeBudiLifecycleDialog,
  OpenChangeBudiLifecycleDialogPayload
} from '../../store/product-groups.actions';

enum FieldNames {
  CONFIRM = 'confirm'
}

@Component({
  selector: 'rims-change-budi-lifecycle-dialog',
  templateUrl: './change-budi-lifecycle-dialog.component.html',
  styleUrls: ['./change-budi-lifecycle-dialog.component.scss'],
  standalone: true,
  imports: [MatLegacyDialogModule, FormsModule, ReactiveFormsModule, MatLegacyCheckboxModule, MatLegacyButtonModule]
})
export class ChangeBudiLifecycleDialogComponent {
  readonly fieldNames = FieldNames;

  form = new UntypedFormGroup({
    [FieldNames.CONFIRM]: new UntypedFormControl(false, Validators.requiredTrue)
  });

  private readonly data: OpenChangeBudiLifecycleDialogPayload = inject(MAT_DIALOG_DATA);

  constructor(
    public readonly dialogRef: MatDialogRef<ChangeBudiLifecycleDialogComponent>,
    private readonly store: Store<AppState>
  ) {}

  onSubmit() {
    this.store.dispatch(
      closeChangeBudiLifecycleDialog({
        ...this.data
      })
    );
    this.dialogRef.close();
  }
}
