<div class="container-fluid mb-3 drag-boundary">
  <div class="row">
    <div class="col-12">
      <h1 mat-dialog-title class="d-flex align-items-center">Filter by {{ config?.displayName }}</h1>
    </div>
    <div class="col-12">
      <mat-dialog-content>
        <form [formGroup]="form">
          <ng-container [ngSwitch]="isSelect">
            <ng-container *ngSwitchCase="false" [ngSwitch]="isDate || isNumber">
              <!-- text -->
              <mat-form-field *ngSwitchCase="false" class="w-100" appearance="fill">
                <mat-label>{{ config?.displayName }}</mat-label>
                <input type="text" matInput formControlName="value" />
              </mat-form-field>
              <!-- date or number -->
              <div *ngSwitchCase="true" class="row">
                <mat-form-field class="col-md-6 pe-md-2" appearance="fill">
                  <mat-label>Operator</mat-label>
                  <mat-select formControlName="operator" (selectionChange)="onOperatorChange($event)">
                    <mat-option *ngFor="let operator of operators" [value]="operator">
                      {{ operator | filterOperatorLabel }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <!-- number -->
                <mat-form-field *ngIf="isNumber; else dateField" class="col-md-6 ps-md-2" appearance="fill">
                  <mat-label>{{ config?.displayName }}</mat-label>
                  <input type="number" min="0" (keydown)="onKeyDown($event)" (paste)="onPaste($event)" matInput formControlName="value" />
                </mat-form-field>

                <!-- date -->
                <ng-template #dateField>
                  <mat-form-field *ngIf="form.get('operator').value === 'DATE_RANGE'; else datePicker" class="col-md-6 ps-md-2" appearance="fill">
                    <mat-label>Select a Date Range</mat-label>
                    <mat-date-range-input [rangePicker]="picker">
                      <input matStartDate readonly placeholder="Start date" #rangeStart [value]="dateRangeStart" />
                      <input matEndDate readonly placeholder="End date" #rangeEnd [value]="dateRangeEnd" (dateChange)="onDateRangeChange(rangeStart, rangeEnd)" />
                    </mat-date-range-input>
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-date-range-picker #picker></mat-date-range-picker>
                  </mat-form-field>
                  <ng-template #datePicker>
                    <mat-form-field *ngIf="form.get('operator').value === '='; else disabledPicker" class="col-md-6 ps-md-2" appearance="fill">
                      <mat-label>Select a Date</mat-label>
                      <input matInput readonly [matDatepicker]="picker" [value]="data.value" (dateChange)="onDateChange($event)" />
                      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                      <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                  </ng-template>
                  <ng-template #disabledPicker>
                    <mat-form-field class="col-md-6 ps-md-2" appearance="fill">
                      <input matInput readonly disabled />
                      <mat-datepicker-toggle matSuffix disabled></mat-datepicker-toggle>
                    </mat-form-field>
                  </ng-template>
                </ng-template>
              </div>
            </ng-container>
            <!-- dropdown -->
            <ng-container *ngSwitchCase="true">
              <mat-form-field class="w-100" appearance="fill">
                <mat-label>{{ config?.displayName }}</mat-label>
                <mat-select (selectionChange)="onSelectChange($event)" multiple [disableOptionCentering]="true" panelClass="select-panel" formControlName="selected">
                  <mat-option *ngFor="let option of options" [value]="option" [matTooltip]="getTooltip(option.display)">
                    {{ option.display }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </ng-container>
          </ng-container>
        </form>
      </mat-dialog-content>
    </div>
    <div class="col-12">
      <mat-dialog-actions class="p-0 justify-content-end">
        <button mat-button [attr.e2e-id]="'rims-table-filter-creation-dialog-save-button'" color="primary" [disabled]="form.invalid || form.pristine" (click)="save()">
          Add Filter
        </button>
        <button mat-button [attr.e2e-id]="'rims-table-filter-creation-dialog-close-button'" (click)="close()">Cancel</button>
      </mat-dialog-actions>
    </div>
  </div>
</div>
