import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { EqualsFilter } from '@rims/lib';
import { of } from 'rxjs';
import { catchError, map, mapTo, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { AuthService } from '../../shared/services/auth/auth.service';
import { DataService } from '../../shared/services/data/data.service';
import { getEntities, getViewGroups } from '../metadata/metadata.actions';
import { noop } from '../shared/shared.actions';
import { AppState } from '../store.state';
import { invalidUserTypeLoginAttempt, login, loginError, loginSuccess, setInstanaData } from './user.actions';

@Injectable()
export class UserEffects {
  private readonly storage = window.sessionStorage;

  constructor(
    private readonly store: Store<AppState>,
    private readonly actions: Actions,
    private readonly authService: AuthService,
    private readonly snackBar: MatSnackBar,
    private readonly router: Router,
    private readonly data: DataService
  ) {}

  login = createEffect(() =>
    this.actions.pipe(
      ofType(login),
      switchMap(() => this.authService.userId),
      switchMap(userId => {
        return this.data
          .getAll('users', ['role', 'contact'], undefined, undefined, undefined, undefined, [
            new EqualsFilter('id', userId)
          ])
          .pipe(
            switchMap(user => of(loginSuccess(user))),
            catchError(err => {
              // 422 Unprocessable Entity
              if (err.status === 422) {
                return of(invalidUserTypeLoginAttempt());
              }
              this.snackBar.open(`❌  Could not find user`, null, {
                duration: 4000,
                horizontalPosition: 'right'
              });
              return of(loginError());
            })
          );
      })
    )
  );

  loginError = createEffect(() =>
    this.actions.pipe(
      ofType(loginError),
      tap(() => {
        this.snackBar.open(
          'We could not connect to the server to log you in. Please try again in a few moments. If this problem persists, please talk to your administrator.'
        );
      }),
      mapTo(noop())
    )
  );

  invalidUserTypeLoginAttempt = createEffect(() =>
    this.actions.pipe(
      ofType(invalidUserTypeLoginAttempt),
      map(() => {
        this.router.navigate(['invalid-user-type-login-attempt']);
        return noop();
      })
    )
  );

  loginSuccess = createEffect(() =>
    this.actions.pipe(
      ofType(loginSuccess),
      switchMap(() => {
        return [setInstanaData(), getEntities(), getViewGroups()];
      })
    )
  );

  setInstanaData = createEffect(() =>
    this.actions.pipe(
      ofType(setInstanaData),
      withLatestFrom(this.store.pipe(select(state => state))),
      map(([_, state]) => {
        const user = state.user.user;
        const meta = state.meta;
        ineum('user', user.id, `${user.firstName} ${user.lastName}`, user?.contact?.eMail);
        ineum('meta', 'version', meta.appVersion);
        ineum('whitelistedOrigins', [/api\.rims\..*\.bbraun\.cloud/]);
        return noop();
      })
    )
  );
}
