import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  Validators
} from '@angular/forms';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyOptionModule } from '@angular/material/legacy-core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
  MatLegacyDialogModule
} from '@angular/material/legacy-dialog';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { MatLegacyProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatLegacySelectModule } from '@angular/material/legacy-select';
import { Store } from '@ngrx/store';
import { EqualsFilter } from '@rims/lib';
import { merge, Observable, of } from 'rxjs';
import { Page } from 'src/app/modules/shared/models/page/page.model';
import { DataService } from 'src/app/modules/shared/services/data/data.service';
import { AppState } from 'src/app/modules/store/store.state';
import { DuplicateValidator } from '../../../shared/validators/duplicate.validator';
import {
  closeAddIdentificationNumberDialog,
  OpenAddIdentificationNumberDialogPayload
} from '../../store/actors.actions';

enum FieldNames {
  TYPE = 'TYPE',
  NUMBER = 'NUMBER',
  CONFIRM = 'CONFIRM'
}

@Component({
  selector: 'rims-add-identification-number-dialog',
  templateUrl: './add-identification-number-dialog.component.html',
  styleUrls: ['./add-identification-number-dialog.component.scss'],
  standalone: true,
  imports: [
    MatLegacyDialogModule,
    FormsModule,
    ReactiveFormsModule,
    MatLegacyFormFieldModule,
    MatLegacySelectModule,
    NgFor,
    MatLegacyOptionModule,
    NgIf,
    MatLegacyProgressBarModule,
    MatLegacyInputModule,
    MatLegacyCheckboxModule,
    MatLegacyButtonModule,
    AsyncPipe
  ]
})
export class AddIdentificationNumberDialogComponent implements OnInit {
  identificationNumberTypes: Observable<Page<any>>;

  readonly fieldNames = FieldNames;

  form = new UntypedFormGroup(
    {
      [FieldNames.TYPE]: new UntypedFormControl(null, Validators.required, this.duplicateActorTypeValidator.bind(this)),
      [FieldNames.NUMBER]: new UntypedFormControl(null, Validators.required),
      [FieldNames.CONFIRM]: new UntypedFormControl(false, Validators.requiredTrue)
    },
    {
      asyncValidators: [this.duplicateActorIdentificationNumberValidator.bind(this)]
    }
  );

  private readonly data: OpenAddIdentificationNumberDialogPayload = inject(MAT_DIALOG_DATA);

  constructor(
    public readonly dialogRef: MatDialogRef<AddIdentificationNumberDialogComponent>,
    private readonly dataService: DataService,
    private readonly store: Store<AppState>,
    private readonly validator: DuplicateValidator
  ) {}

  ngOnInit() {
    this.identificationNumberTypes = this.dataService.getAll(
      'identificationnumbertypes',
      undefined,
      undefined,
      Number.MAX_SAFE_INTEGER
    );

    merge(this.form.get(FieldNames.TYPE).valueChanges, this.form.get(FieldNames.NUMBER).valueChanges).subscribe(() => {
      // reset confirm when type or number changes
      this.form.get(FieldNames.CONFIRM).reset();
    });
  }

  onSubmit() {
    this.store.dispatch(
      closeAddIdentificationNumberDialog({
        actor: this.data.actor,
        identificationNumberType: this.form.value[FieldNames.TYPE],
        identificationNumber: this.form.value[FieldNames.NUMBER]
      })
    );
    this.dialogRef.close();
  }

  private duplicateActorTypeValidator(formGroup: UntypedFormGroup): Observable<ValidationErrors | null> {
    const actorFilter = new EqualsFilter('actor', this.data.actor);
    const typeFilter = new EqualsFilter('type', formGroup.value);

    return this.validator.duplicateValidator('actoridentificationnumbers', [actorFilter, typeFilter]);
  }

  private duplicateActorIdentificationNumberValidator(
    formGroup: UntypedFormGroup
  ): Observable<ValidationErrors | null> {
    const typeFilter = new EqualsFilter('type', formGroup.value[FieldNames.TYPE]);
    const numberFilter = new EqualsFilter('number', formGroup.value[FieldNames.NUMBER]);

    // only validate identification number when confirm is checked, because
    // - we would otherwise interfere with 'duplicateActorTypeValidator' which also checks against /actoridentificationnumbers
    // - we would validate on each key stroke
    if (!this.form.get(FieldNames.CONFIRM).value) {
      return of(null);
    }

    return this.validator.duplicateValidator('actoridentificationnumbers', [typeFilter, numberFilter]);
  }
}
