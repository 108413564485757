import { Filter, FilterOperator } from '@rims/lib';
import type { FilterConfiguration } from '../../../store/metadata/metadata.actions';
import { ManagedPipeToken } from '../../pipes/dynamic.pipe';

const productGroupConfigurations = [
  {
    fieldName: 'groupType',
    displayName: 'Group Type',
    filterType: 'number',
    optionUrl: 'productgrouptypes',
    optionField: 'shortName'
  },
  {
    fieldName: 'number',
    displayName: 'Group Number'
  },
  {
    fieldName: 'name',
    displayName: 'Group Name'
  },
  {
    fieldName: 'productType',
    displayName: 'Product Type',
    filterType: 'number',
    optionUrl: 'producttypes',
    optionField: 'name'
  },
  {
    fieldName: 'coe',
    displayName: 'CoE',
    filterType: 'number',
    optionUrl: 'coes',
    optionField: 'name'
  },
  {
    fieldName: 'intendedPurpose',
    displayName: 'Intended Purpose',
    optionUrl: 'intendedpurposes',
    optionField: 'intendedPurpose'
  },
  {
    fieldName: 'budiInfo2.animalTissuesCellsPresence',
    displayName: 'Animal Tissues Cells Presence',
    filterType: 'boolean'
  },
  {
    fieldName: 'budiInfo2.humanTissuesCellsPresence',
    displayName: 'Human Tissues Cells Presence',
    filterType: 'boolean'
  },
  {
    fieldName: 'budiInfo2.humanBloodOrPlasmaPresence',
    displayName: 'Human Blood or Plasma Presence',
    filterType: 'boolean'
  },
  {
    fieldName: 'budiInfo2.medicinalSubstancePresence',
    displayName: 'Medicinal Substance Presence',
    filterType: 'boolean'
  },
  {
    fieldName: 'budiInfo2.isSterile',
    displayName: 'Sterile',
    filterType: 'boolean'
  },
  {
    fieldName: 'budiInfo2.multipleGtinsForBaseUnit',
    displayName: 'Multiple GTINs for Base Unit',
    filterType: 'boolean'
  },
  {
    fieldName: 'budiInfo2.active',
    displayName: 'Active',
    filterType: 'boolean'
  },
  {
    fieldName: 'budiInfo2.administeringMedicine',
    displayName: 'Administering Medicine',
    filterType: 'boolean'
  },
  {
    fieldName: 'budiInfo2.implantable',
    displayName: 'Implantable',
    filterType: 'boolean'
  },
  {
    fieldName: 'budiInfo2.measuringFunction',
    displayName: 'Measuring Function',
    filterType: 'boolean'
  },
  {
    fieldName: 'budiInfo2.reusable',
    displayName: 'Reusable Surgical Instrument',
    filterType: 'boolean'
  },
  {
    fieldName: 'budiInfo2.systemProcedurePackType',
    optionUrl: 'systemprocedurepacktypes',
    displayName: 'System Procedure Pack Type'
  },
  {
    fieldName: 'budiInfo2.multiComponentType',
    optionUrl: 'multicomponenttypes',
    displayName: 'Multi Component Type'
  },
  {
    fieldName: 'budiInfo2.specialDeviceType',
    optionUrl: 'specialdevicetypes',
    displayName: 'Special Device Type'
  },
  {
    fieldName: 'budiInfo2.riskClass',
    optionUrl: 'riskclasses',
    optionField: 'class',
    optionExpand: ['legislation'],
    optionFilters: [
      new Filter({
        fieldName: 'legislation.shortName',
        operator: FilterOperator.IN,
        value: 'MDR,IVDR',
        readOnly: true
      })
    ],
    optionCustomPipe: {
      token: 'riskclass' as ManagedPipeToken
    },
    displayName: 'Risk Class'
  },
  {
    fieldName: 'budiInfo2.plannedTransitionToMdr',
    displayName: 'Planned Transition to MDR',
    filterType: 'date'
  },
  {
    fieldName: 'budiInfo2.lifecycle',
    optionUrl: 'budilifecycles',
    displayName: 'Lifecycle'
  }
];

const itemConfigurations = [
  {
    fieldName: 'itemNumber',
    displayName: 'Item Number'
  },
  {
    fieldName: 'product.name',
    displayName: 'Product Name'
  },
  {
    fieldName: 'itemNumber2.name',
    displayName: 'Material Name'
  },
  {
    fieldName: 'itemNumber2.materialStatus',
    displayName: 'Material Status',
    options: ['M1', 'M2', 'M3', 'M4']
  },
  {
    fieldName: 'itemNumber2.materialStatusChangeDate',
    displayName: 'Material Status Change',
    filterType: 'date'
  },
  {
    fieldName: 'itemNumber2.distributionStatus',
    displayName: 'Distribution Status',
    options: ['V0', 'V1', 'V2', 'V3', 'V4', 'V5']
  },
  {
    fieldName: 'itemNumber2.distributionStatusChangeDate',
    displayName: 'Distribution Status Change',
    filterType: 'date'
  },
  {
    fieldName: 'itemNumber2.shelfLife',
    displayName: 'Shelf Life',
    filterType: 'number'
  },
  {
    fieldName: 'itemNumber2.marketScope',
    displayName: 'Market Scope',
    options: ['OEM', 'global', 'local']
  },
  {
    fieldName: 'isSterile',
    displayName: 'Sterile',
    filterType: 'boolean'
  },
  {
    fieldName: 'active',
    displayName: 'Active',
    filterType: 'boolean'
  },
  {
    fieldName: 'administeringMedicine',
    displayName: 'Administering Medicine',
    filterType: 'boolean'
  },
  {
    fieldName: 'implantable',
    displayName: 'Implantable',
    filterType: 'boolean'
  },
  {
    fieldName: 'reusable',
    displayName: 'Reusable',
    filterType: 'boolean'
  },
  {
    fieldName: 'measuringFunction',
    displayName: 'Measuring Function',
    filterType: 'boolean'
  },
  {
    fieldName: 'budiInfo2.lifecycle',
    optionUrl: 'budilifecycles',
    displayName: 'BUDI Lifecycle'
  }
];

const containerConfigurations = [
  {
    fieldName: 'type.name',
    displayName: 'Type',
    optionUrl: 'containertypes',
    optionField: 'name'
  },
  {
    fieldName: 'size',
    displayName: 'Size',
    filterType: 'number'
  },
  {
    fieldName: 'substanceUnit.name',
    displayName: 'Unit',
    optionUrl: 'units',
    optionField: 'name'
  },
  {
    fieldName: 'shape.name',
    displayName: 'Shape',
    optionUrl: 'containershapes',
    optionField: 'name'
  },
  {
    fieldName: 'color.name',
    displayName: 'Color',
    optionUrl: 'containercolors',
    optionField: 'name'
  },
  {
    fieldName: 'cap.name',
    displayName: 'Cap',
    optionUrl: 'containercaps',
    optionField: 'name'
  },
  {
    fieldName: 'IsAdvanced',
    displayName: 'Advanced',
    filterType: 'boolean'
  }
];

const documentConfigurations = [
  {
    fieldName: 'documentType',
    displayName: 'Type',
    optionUrl: 'documenttypes',
    optionField: 'name'
  },
  {
    fieldName: 'docNumber',
    displayName: 'Number'
  },
  {
    fieldName: 'docRevision',
    displayName: 'Revision'
  },
  {
    fieldName: 'effectiveDate',
    filterType: 'date',
    displayName: 'Effective Date'
  },
  {
    fieldName: 'expiryDate',
    filterType: 'date',
    displayName: 'Expiry Date'
  },
  {
    fieldName: 'dmsObject.dmsId',
    displayName: 'DMS Object ID'
  }
];

const actorConfigurations = [
  {
    fieldName: 'actorType',
    displayName: 'Type',
    optionUrl: 'actortypes',
    optionField: 'name'
  },
  {
    fieldName: 'company2.name',
    displayName: 'Company',
    optionUrl: 'companies',
    optionField: 'name'
  },
  {
    fieldName: 'company2.address.city',
    displayName: 'City',
    optionUrl: 'addresses/cities'
  },
  {
    fieldName: 'company2.address.country.id',
    displayName: 'Country',
    optionUrl: 'countries',
    optionField: 'name'
  }
];

const companyConfigurations = [
  {
    fieldName: 'name',
    displayName: 'Name'
  },
  {
    fieldName: 'address.street_name',
    displayName: 'Street'
  },
  {
    fieldName: 'address.street_number',
    displayName: 'Street No.'
  },
  {
    fieldName: 'address.zip_code',
    displayName: 'ZIP'
  },
  {
    fieldName: 'address.city',
    displayName: 'City',
    optionUrl: 'addresses/cities'
  },
  {
    fieldName: 'address.country.id',
    displayName: 'Country',
    optionUrl: 'countries',
    optionField: 'name'
  }
];

const nomenclatureConfigurations = [
  {
    fieldName: 'nomenclatureType2.name',
    displayName: 'Nomenclature Type',
    optionUrl: 'nomenclaturetypes',
    optionField: 'name'
  },
  {
    fieldName: 'term',
    displayName: 'Term'
  },
  {
    fieldName: 'number',
    displayName: 'Number'
  }
];

const userConfigurations = [
  {
    fieldName: 'id',
    displayName: 'Id'
  },
  {
    fieldName: 'firstName',
    displayName: 'First Name'
  },
  {
    fieldName: 'lastName',
    displayName: 'Name'
  },
  {
    fieldName: 'role.id',
    displayName: 'Role',
    optionUrl: 'roles',
    optionField: 'name'
  },
  {
    fieldName: 'contact.eMail',
    displayName: 'E-Mail'
  }
];

const pgiConfigurations = itemConfigurations.map(conf => ({ ...conf, fieldName: `item2.${conf.fieldName}` }));

export const filterConfigurations: { [key: string]: FilterConfiguration[] } = {
  '/view/productgroups': productGroupConfigurations,
  '/view/items': itemConfigurations,
  '/view/productgroupitems': pgiConfigurations,
  '/view/containers': containerConfigurations,
  '/view/documents': documentConfigurations,
  '/view/actors': actorConfigurations,
  '/view/companies': companyConfigurations,
  '/view/nomenclatures': nomenclatureConfigurations,
  '/view/users': userConfigurations
};
