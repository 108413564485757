import { ErrorHandler, Injectable, NgZone } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ErrorSnackbarComponent } from './modules/shared/components/snackbar/error-snackbar/error-snackbar.component';

@Injectable()
export class RimsErrorHandler implements ErrorHandler {
  constructor(private readonly snackbar: MatSnackBar, private readonly zone: NgZone) {}

  handleError(error) {
    this.reportToInstana(error);
    this.showErrorToUser(error);
    console.error(error);
  }

  private showErrorToUser(error: any) {
    this.zone.run(() => {
      const duration = 8000;
      this.snackbar.openFromComponent(ErrorSnackbarComponent, {
        data: {
          error,
          duration
        },
        horizontalPosition: 'right',
        verticalPosition: 'bottom',
        panelClass: 'error-snackbar',
        duration
      });
    });
  }

  private reportToInstana(error: any) {
    if (window.location.hostname !== 'localhost') {
      ineum('reportError', error);
    }
  }
}
