<div class="container-fluid mb-3 drag-boundary">
  <div class="row">
    <div class="col-12">
      <h1 mat-dialog-title class="m-0">{{ getTitle() }}</h1>
      <span class="color-grey d-block" *ngIf="data.referenceName && data.toName">
        {{ getAddToTxt() }}
      </span>
      <ng-container class="col-12" *ngIf="forbiddenTransfer">
        <span class="d-block color-grey mt-3 mb-3">None of the selected responsibilities can be transferred due to insufficient rights.</span>
      </ng-container>
      <ng-container class="col-12" *ngIf="restrictedTransfer">
        <span class="d-block color-grey mt-3 mb-3">Some responsibilities will be skipped due to insufficient rights:</span>
        <ul class="ps-4">
          <li *ngFor="let responsibility of data.restrictedResponsibilities.slice(0, 10)">
            {{ responsibility?.department2?.shortName }} - {{ responsibility?.productGroup2?.number }} - {{ responsibility?.productGroup2?.name }}
          </li>
          <li *ngIf="data.restrictedResponsibilities.length > 10">...</li>
        </ul>
      </ng-container>

      <span class="d-block color-grey mt-3" *ngIf="forbiddenChildren">For this group type, no allowed child group types are configured. Please talk to you administrator.</span>
      <span class="d-block color-grey" *ngIf="data.key === 'product_group_actor' && data.isABudi && !data.sppt">
        The <b>System Procedure Pack Type</b> for this BUDI is currently not defined.<br />
        It needs to be configured when adding an actor of type <i>Manufacturer</i> or <i>System/Procedure Pack Producer</i>.
      </span>
    </div>
  </div>
  <div class="row pt-2 mb-4">
    <div class="col-12" *ngIf="!forbiddenChildren && !forbiddenTransfer && !restrictedTransfer">
      <form [formGroup]="form">
        <mat-form-field *ngIf="data.dropdown" class="w-100" appearance="fill">
          <mat-label>{{ getDropdownTitle() }}</mat-label>
          <mat-select [formControlName]="fieldNames.DROPDOWN" (selectionChange)="onSelectionChange()">
            <mat-option *ngFor="let drop of dropdownValues | async" [value]="drop.id">
              {{ drop | name : undefined : true : reverse }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <rims-autocomplete
          #autocomplete
          [entity]="data.entityName"
          [name]="name"
          [label]="searchLabel"
          [placeholder]="placeholder"
          [notFoundHint]="notFoundHint"
          [duplicateHint]="duplicateHint"
          [emptyHint]="data.emptyHint"
          [expand]="data.expand"
          [target]="target"
          [getFilters]="getFilters.bind(this)"
          [multiple]="data.multiple ?? false"
          [pipeMultiple]="data.pipeMultiple ?? true"
          [pasteMultiple]="data.pasteMultiple ?? false"
          [searchTarget]="data.searchTarget"
          (selected)="onSelected($event)"
        ></rims-autocomplete>
        <ng-container *ngIf="{ confirm: confirmOverwriteItems | async, txt: confirmOverwriteItemsTxt | async } as overwriteItems">
          <mat-checkbox
            [disabled]="!overwriteItemsPermission"
            [matTooltip]="overwriteItemsPermission ? '' : 'Insufficient rights to add items with discrepancy between (E)BUDI properties'"
            *ngIf="overwriteItems.confirm"
            color="optional"
            [formControlName]="fieldNames.CONFIRM_OVERWRITE_ITEMS"
            ><span [innerHtml]="overwriteItems.txt"></span
          ></mat-checkbox>
        </ng-container>

        <mat-checkbox *ngIf="data.confirm" [formControlName]="fieldNames.CONFIRM"><span [innerHtml]="getConfirmTxt()"></span></mat-checkbox>
        <mat-checkbox *ngIf="data.transfer" color="warn" [formControlName]="fieldNames.CONFIRM_REMOVE_RESPONSIBILITIES">
          <span [innerHtml]="getConfirmRemoveTxt()"></span
        ></mat-checkbox>

        <ng-container *ngIf="data.isABudi && !data.sppt && isSpptActor()">
          <mat-form-field class="w-100" appearance="fill">
            <mat-label>System Procedure Pack Type</mat-label>
            <mat-select [formControlName]="fieldNames.DROPDOWN_SPPT">
              <mat-option *ngFor="let type of sppTypes" [value]="type" [disabled]="!isMatchingType(type.notApplicable)">{{ type.name }}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-checkbox style="display: block" [disabled]="!form.get(fieldNames.DROPDOWN_SPPT).value" [formControlName]="fieldNames.CONFIRM_SPPT">
            <span [innerHtml]="getConfirmSpptTxt()"></span
          ></mat-checkbox>
        </ng-container>
      </form>
    </div>
  </div>

  <mat-dialog-actions class="p-0 justify-content-end">
    <button *ngIf="restrictedTransfer" mat-button [attr.e2e-id]="'submit-button'" color="primary" (click)="restrictedTransfer = false">Continue</button>
    <button *ngIf="!restrictedTransfer" mat-button [attr.e2e-id]="'submit-button'" color="primary" (click)="onSubmit()" [disabled]="form.invalid || form.pending">
      {{ getActionTxt() }}
    </button>
    <button mat-button [attr.e2e-id]="'close-button'" mat-dialog-close>Cancel</button>
  </mat-dialog-actions>
</div>
