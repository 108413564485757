import { ViewportScroller } from '@angular/common';
import { NgModule } from '@angular/core';
import { Event, NavigationEnd, NavigationStart, Router, RouterModule, Routes } from '@angular/router';
import { Store } from '@ngrx/store';
import { autoLoginPartialRoutesGuard } from 'angular-auth-oidc-client';
import { debounceTime, filter, take } from 'rxjs/operators';
import { getTieredTitle } from './modules/shared/utils/metadata';
import { isLoading } from './modules/store/store.state';
import { ViewByUrlParamResolver } from './modules/view/resolvers/view-by-url-param.resolver';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'invalid-user-type-login-attempt',
        loadComponent: () =>
          import(
            './modules/shared/views/invalid-user-type-login-attempt/invalid-user-type-login-attempt.component'
          ).then(m => m.InvalidUserTypeLoginAttemptComponent),
        data: {
          meta: {
            title: 'Invalid User Type'
          }
        }
      },
      {
        path: 'version-information',
        redirectTo: '/docs/version-information'
      },
      {
        path: 'docs',
        loadChildren: () => import('./modules/documentation/documentation.routes'),
        canActivate: [autoLoginPartialRoutesGuard]
      },
      {
        path: 'home',
        loadComponent: () => import('./modules/+home/home.component').then(m => m.HomeComponent),
        title: getTieredTitle('Home'),
        canActivate: [autoLoginPartialRoutesGuard]
      },
      {
        path: 'view',
        children: [
          {
            path: 'products',
            loadChildren: () => import('./modules/products/products.routes')
          },
          {
            path: 'actors',
            loadChildren: () => import('./modules/actors/actors.routes')
          },
          {
            path: 'addresses',
            loadChildren: () => import('./modules/addresses/addresses.routes')
          },
          {
            path: 'documents',
            loadChildren: () => import('./modules/documents/documents.routes')
          },
          {
            path: 'document-types',
            loadChildren: () => import('./modules/document-types/document-types.routes')
          },
          {
            path: 'extdmsobjects',
            loadChildren: () => import('./modules/ext-dms-objects/ext-dms-objects.routes')
          },
          {
            path: 'items',
            loadChildren: () => import('./modules/items/items.routes')
          },
          {
            path: 'productgroups',
            loadChildren: () => import('./modules/product-groups/product-groups.routes')
          },
          {
            path: 'roles',
            loadChildren: () => import('./modules/roles/roles.routes')
          },
          {
            path: 'productgrouptypes',
            loadChildren: () => import('./modules/product-group-types/product-group-types.routes')
          },
          {
            path: 'companies',
            loadChildren: () => import('./modules/companies/companies.routes')
          },
          {
            path: 'nomenclatures',
            loadChildren: () => import('./modules/nomenclatures/nomenclatures.routes')
          },
          {
            path: 'containers',
            loadChildren: () => import('./modules/containers/containers.routes')
          },
          {
            path: 'users',
            loadChildren: () => import('./modules/users/users.routes')
          },
          {
            path: 'permissions',
            loadChildren: () => import('./modules/permissions/permissions.routes')
          },
          {
            path: 'controllersettings',
            loadChildren: () => import('./modules/controller-settings/controller-settings.routes')
          },
          {
            path: 'intendedpurposes',
            loadChildren: () => import('./modules/intended-purposes/intended-purposes.routes')
          },
          {
            path: ':viewId',
            loadComponent: () => import('./modules/view/views/view/view.component').then(m => m.ViewComponent),
            resolve: {
              result: ViewByUrlParamResolver
            }
          },
          {
            path: '**',
            redirectTo: '..'
          }
        ],
        canActivate: [autoLoginPartialRoutesGuard]
      },
      {
        path: '**',
        redirectTo: 'home',
        pathMatch: 'full'
      }
    ]
  },
  {
    path: '**',
    redirectTo: 'home',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule {
  private popstate = false;
  private positions = new Map<string, [number, number]>();
  private previousUrl: string;

  constructor(private router: Router, private viewportScroller: ViewportScroller, private store: Store) {
    // scroll to top on all route changes which aren't solely query param changes
    // scroll to previously saved scroll position when navigating back
    this.router.events.subscribe((e: Event) => {
      if (e instanceof NavigationStart) {
        this.handleBackNavigation(e);
      } else if (e instanceof NavigationEnd) {
        this.setScrollPosition(e);
      }
    });
  }

  handleBackNavigation(e: NavigationStart) {
    if (e.navigationTrigger === 'popstate') {
      this.popstate = true;
    } else if (!e.url.includes('?')) {
      // save scroll position for next popstate
      this.positions.set(this.previousUrl, this.viewportScroller.getScrollPosition());
    }
  }

  setScrollPosition(e: NavigationEnd) {
    // the current url will be the 'previousUrl' on the next NavigationStart event
    this.previousUrl = e.url.split('?')[0];
    if (this.popstate) {
      this.popstate = false;
      this.store
        .select(isLoading)
        .pipe(
          debounceTime(400),
          filter(loading => !loading),
          take(1)
        )
        .subscribe(() => {
          const url = e.url.split('?')[0];
          const position = this.positions.get(url) || [0, 0];
          this.viewportScroller.scrollToPosition(position);
        });
    } else if (!e.url.includes('?')) {
      this.viewportScroller.scrollToPosition([0, 0]);
    }
  }
}
