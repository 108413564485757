import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap } from 'rxjs/operators';
import { DataService } from '../../shared/services/data/data.service';
import { noop } from '../../store/shared/shared.actions';
import { restartController } from './controller-settings.actions';

@Injectable()
export class ControllerSettingsEffects {
  restartController = createEffect(() =>
    this.actions.pipe(
      ofType(restartController),
      switchMap(payload => this.dataService.restartController(payload.entityId)),
      map(_ => noop())
    )
  );

  constructor(private readonly actions: Actions, private readonly dataService: DataService) {}
}
