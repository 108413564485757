import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { DataService } from '../../shared/services/data/data.service';
import { createRecord } from '../../store/data/data.actions';
import { createAddressAndCompany, createCompany } from './companies.actions';

@Injectable()
export class CompaniesEffects {
  constructor(private readonly actions: Actions, private readonly dataService: DataService) {}

  createAddressAndCompany = createEffect(() =>
    this.actions.pipe(
      ofType(createAddressAndCompany),
      switchMap(payload => {
        return this.dataService
          .create('addresses', {
            ...payload.address
          })
          .pipe(
            map(adresses => {
              return createCompany({
                company: payload.company,
                address: adresses[0].id
              });
            })
          );
      })
    )
  );

  createCompany = createEffect(() =>
    this.actions.pipe(
      ofType(createCompany),
      switchMap(payload => {
        return of(
          createRecord({
            url: 'companies',
            payload: {
              ...payload.company,
              address: payload.address
            },
            navigateTo: records => `/view/companies/${records[0].id}`
          })
        );
      })
    )
  );
}
