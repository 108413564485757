import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyOptionModule } from '@angular/material/legacy-core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
  MatLegacyDialogModule
} from '@angular/material/legacy-dialog';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatLegacySelectModule } from '@angular/material/legacy-select';
import { Store } from '@ngrx/store';
import { EqualsFilter } from '@rims/lib';
import { BehaviorSubject, Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { Page } from 'src/app/modules/shared/models/page/page.model';
import { DataService } from 'src/app/modules/shared/services/data/data.service';
import { AppState } from 'src/app/modules/store/store.state';
import { LegislationOptionFormatPipe } from '../../../shared/pipes/legislation-option-format.pipe';
import { RiskClassOptionFormatPipe } from '../../../shared/pipes/risk-class-option-format.pipe';
import { closeAddDialog } from '../../../store/shared/shared.actions';
import { OpenAddRiskClassToItemDialogPayload } from '../../store/item.actions';
import { RiskClassValidator } from '../../validators/risk-class.validator';

enum FieldNames {
  LEGISLATION = 'LEGISLATION',
  RISK_CLASS = 'RISK_CLASS'
}

@Component({
  selector: 'rims-add-risk-class-to-item-dialog',
  templateUrl: './add-risk-class-to-item-dialog.component.html',
  styleUrls: ['./add-risk-class-to-item-dialog.component.scss'],
  standalone: true,
  imports: [
    MatLegacyDialogModule,
    FormsModule,
    ReactiveFormsModule,
    MatLegacyFormFieldModule,
    MatLegacySelectModule,
    NgFor,
    MatLegacyOptionModule,
    NgIf,
    MatLegacyProgressBarModule,
    MatLegacyButtonModule,
    AsyncPipe,
    LegislationOptionFormatPipe,
    RiskClassOptionFormatPipe
  ]
})
export class AddRiskClassToItemDialogComponent implements OnInit {
  filteredOptions: Observable<any[]>;
  legislations: Observable<Page<any>>;
  riskClasses: Observable<Page<any>>;
  readonly relationAlreadyExists = new BehaviorSubject<boolean>(false);
  readonly fieldNames = FieldNames;
  availableRiskClassesLabel = new BehaviorSubject<string>('Select a legislation first');

  form = new UntypedFormGroup({
    [FieldNames.LEGISLATION]: new UntypedFormControl(null, Validators.required),
    [FieldNames.RISK_CLASS]: new UntypedFormControl(
      {
        value: null,
        disabled: true
      },
      {
        validators: [Validators.required],
        asyncValidators: [this.duplicateRiskClassValidator.bind(this)]
      }
    )
  });

  private readonly data: OpenAddRiskClassToItemDialogPayload = inject(MAT_DIALOG_DATA);

  constructor(
    public readonly dialogRef: MatDialogRef<AddRiskClassToItemDialogComponent>,
    private readonly dataService: DataService,
    private readonly store: Store<AppState>,
    private readonly validator: RiskClassValidator
  ) {}

  ngOnInit() {
    this.legislations = this.dataService.getAll('legislations', undefined, 0, Number.MAX_SAFE_INTEGER);
    this.riskClasses = this.form.get(FieldNames.LEGISLATION).valueChanges.pipe(
      tap(() => this.form.get(FieldNames.RISK_CLASS).setValue(null)),
      switchMap((legislationId: number) => {
        return this.dataService.getAll('riskclasses', undefined, 0, Number.MAX_SAFE_INTEGER, undefined, undefined, [
          new EqualsFilter('legislation', legislationId)
        ]);
      }),
      tap((page: Page<any>) => {
        if (!page || page.resultsSize === 0) {
          this.availableRiskClassesLabel.next('No risk classes in this legislation');
          this.form.get(FieldNames.RISK_CLASS).disable();
          this.form.setErrors({ riskClassRequired: true });
        } else {
          this.availableRiskClassesLabel.next(`${page.resultsSize} risk classes found`);
          this.form.get(FieldNames.RISK_CLASS).enable();
        }
      })
    );
  }

  onSubmit() {
    this.store.dispatch(
      closeAddDialog({
        entityIds: [this.form.value[FieldNames.RISK_CLASS]],
        key: 'item_risk_class',
        entityName: 'riskClass',
        toName: 'item',
        toId: this.data.item
      })
    );
    this.dialogRef.close();
  }

  private duplicateRiskClassValidator(control: UntypedFormControl) {
    const item = this.data.item;
    const riskClass = control.value;
    const legislation = this.form.value[FieldNames.LEGISLATION];

    return this.validator.duplicateRiskClassValidator(item, riskClass, legislation);
  }
}
