import { AsyncPipe, NgFor, NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault, NgTemplateOutlet } from '@angular/common';
import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyOptionModule } from '@angular/material/legacy-core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
  MatLegacyDialogModule
} from '@angular/material/legacy-dialog';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { MatLegacyListModule } from '@angular/material/legacy-list';
import { MatLegacyProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatLegacySelectModule } from '@angular/material/legacy-select';
import { Store } from '@ngrx/store';
import { Container, ContainerCap, ContainerColor, ContainerShape, ContainerType, Unit } from '@rims/database';
import { AllowedContainerTypes, Direction, EqualsFilter, Filter, FilterOperator } from '@rims/lib';
import { UniversalValidators } from 'ngx-validators';
import { Observable, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, switchMap, tap, throttleTime } from 'rxjs/operators';
import { ContainerOptionFormatPipe } from '../../../containers/pipes/container-option-format.pipe';
import { ContainerTypeFormatPipe } from '../../../containers/pipes/container-type-format.pipe';
import { Page } from '../../../shared/models/page/page.model';
import { NamePipe } from '../../../shared/pipes/name.pipe';
import { DataService } from '../../../shared/services/data/data.service';
import { AppState } from '../../../store/store.state';
import {
  closeAddContainersToProductGroupDialog,
  OpenAddContainerToProductGroupDialogPayload
} from '../../store/product-groups.actions';

enum FormControlNames {
  CONTAINER_TYPE = 'type',
  SIZE = 'size',
  SIZE_COMPARISON_MODE = 'sizeComparisonMode',
  UNIT = 'substanceUnit',
  CAP = 'cap',
  SHAPE = 'shape',
  COLOR = 'color',
  SELECTED_CONTAINERS = 'selectedContainers',
  ALLOW_ADVANCED_FILTERS = 'allowAdvancedFilters',
  ENABLE_ADVANCED_FILTERS = 'enableAdvancedFilters'
}

@Component({
  selector: 'rims-add-containers-to-product-group-dialog',
  templateUrl: './add-containers-to-product-group-dialog.component.html',
  styleUrls: ['./add-containers-to-product-group-dialog.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    MatLegacyDialogModule,
    MatLegacyCheckboxModule,
    FormsModule,
    ReactiveFormsModule,
    MatLegacyProgressBarModule,
    MatLegacyFormFieldModule,
    MatLegacyInputModule,
    MatLegacyAutocompleteModule,
    NgFor,
    MatLegacyOptionModule,
    MatLegacySelectModule,
    NgTemplateOutlet,
    MatLegacyListModule,
    MatLegacyButtonModule,
    NgSwitch,
    NgSwitchCase,
    NgSwitchDefault,
    AsyncPipe,
    NamePipe,
    ContainerOptionFormatPipe,
    ContainerTypeFormatPipe
  ]
})
export class AddContainersToProductGroupDialogComponent implements OnInit, OnDestroy {
  readonly allowedContainerTypes = AllowedContainerTypes;
  readonly sizeComparisonMode = FilterOperator;
  readonly formControlNames = FormControlNames;
  readonly form = new UntypedFormGroup({
    [FormControlNames.CONTAINER_TYPE]: new UntypedFormControl(null, [
      Validators.required,
      UniversalValidators.type('object')
    ]),
    [FormControlNames.SIZE_COMPARISON_MODE]: new UntypedFormControl(FilterOperator.EQUALS, [
      Validators.required,
      UniversalValidators.type('string')
    ]),
    [FormControlNames.SIZE]: new UntypedFormControl(null, [UniversalValidators.type('number')]),
    [FormControlNames.UNIT]: new UntypedFormControl(null, [UniversalValidators.type('object')]),
    [FormControlNames.CAP]: new UntypedFormControl(null, [UniversalValidators.type('object')]),
    [FormControlNames.SHAPE]: new UntypedFormControl(null, [UniversalValidators.type('object')]),
    [FormControlNames.COLOR]: new UntypedFormControl(null, [UniversalValidators.type('object')]),
    [FormControlNames.ALLOW_ADVANCED_FILTERS]: new UntypedFormControl(false, [
      Validators.required,
      UniversalValidators.type('boolean')
    ]),
    [FormControlNames.ENABLE_ADVANCED_FILTERS]: new UntypedFormControl(false, [UniversalValidators.type('boolean')])
  });
  readonly containerSelectionForm = new UntypedFormGroup({
    [FormControlNames.SELECTED_CONTAINERS]: new UntypedFormControl(null, [
      Validators.required,
      UniversalValidators.type('object')
    ])
  });

  readonly data: OpenAddContainerToProductGroupDialogPayload = inject(MAT_DIALOG_DATA);

  readonly containerTypeDisplayFunction = ContainerTypeFormatPipe.displayFunction;
  containerTypes: Observable<Page<ContainerType>>;
  containerCaps: Observable<Page<ContainerCap>>;
  containerShapes: Observable<Page<ContainerShape>>;
  containerColors: Observable<Page<ContainerColor>>;
  substanceUnits: Observable<Page<Unit>>;
  containers: Observable<Page<Container>>;
  loadingOptions = false;

  private containerTypeStatusSub: Subscription;
  private enableAdvancedFiltersChangeSub: Subscription;

  constructor(
    private readonly dataService: DataService,
    private readonly store: Store<AppState>,
    private readonly dialogRef: MatDialogRef<AddContainersToProductGroupDialogComponent>
  ) {}

  ngOnInit(): void {
    this.substanceUnits = this.dataService.getAll('units');
    this.containerCaps = this.dataService.getAll('containercaps');
    this.containerShapes = this.dataService.getAll('containershapes');
    this.containerColors = this.dataService.getAll('containercolors');
    this.containerTypes = this.form.get(FormControlNames.CONTAINER_TYPE).valueChanges.pipe(
      filter(query => typeof query === 'string'),
      tap(() => (this.loadingOptions = true)),
      debounceTime(500),
      distinctUntilChanged(),
      switchMap(query => {
        return this.dataService.getAll<ContainerType>(
          'containertypes',
          undefined,
          undefined,
          undefined,
          { active: undefined, direction: Direction.BEGINS_WITH_ASC },
          undefined,
          [...this.getAdvancedFilter()],
          query
        );
      }),
      tap(() => (this.loadingOptions = false))
    );
    this.containers = this.form.valueChanges.pipe(
      filter(() => this.form.get(FormControlNames.CONTAINER_TYPE).valid),
      throttleTime(100, undefined, {
        trailing: true,
        leading: false
      }),
      switchMap(value => {
        const filters: Filter[] = [
          ...this.getAdvancedFilter(),
          new EqualsFilter(
            FormControlNames.CONTAINER_TYPE,
            (value[FormControlNames.CONTAINER_TYPE] as ContainerType).id
          )
        ];
        if (typeof value[FormControlNames.SIZE] === 'number') {
          filters.push(
            new Filter({
              fieldName: FormControlNames.SIZE,
              value: value[FormControlNames.SIZE],
              operator: value[FormControlNames.SIZE_COMPARISON_MODE]
            })
          );
        }
        if (value[FormControlNames.UNIT]) {
          filters.push(new EqualsFilter(FormControlNames.UNIT, (value[FormControlNames.UNIT] as Unit).id));
        }
        if (value[FormControlNames.CAP]) {
          filters.push(new EqualsFilter(FormControlNames.CAP, (value[FormControlNames.CAP] as ContainerCap).id));
        }
        if (value[FormControlNames.SHAPE]) {
          filters.push(new EqualsFilter(FormControlNames.SHAPE, (value[FormControlNames.SHAPE] as ContainerShape).id));
        }
        if (value[FormControlNames.COLOR]) {
          filters.push(new EqualsFilter(FormControlNames.COLOR, (value[FormControlNames.COLOR] as ContainerColor).id));
        }
        return this.dataService.getAll<Container>(
          'containers',
          ['substanceUnit', 'type', 'cap', 'shape', 'color', 'containerProductionSites.company2.address'],
          undefined,
          Number.MAX_SAFE_INTEGER,
          undefined,
          undefined,
          filters
        );
      }),
      tap(() => this.containerSelectionForm.get(FormControlNames.SELECTED_CONTAINERS).reset())
    );

    this.containerTypeStatusSub = this.form
      .get(FormControlNames.CONTAINER_TYPE)
      .statusChanges.pipe(
        tap(() => {
          this.updateFormControls();
        })
      )
      .subscribe();

    this.enableAdvancedFiltersChangeSub = this.form
      .get(FormControlNames.ENABLE_ADVANCED_FILTERS)
      .valueChanges.pipe(tap((enabled: boolean) => this.onAdvancedFiltersActiveChange(enabled)))
      .subscribe();

    this.updateFormControls();
  }

  ngOnDestroy() {
    if (this.containerTypeStatusSub && !this.containerTypeStatusSub.closed) {
      this.containerTypeStatusSub.unsubscribe();
    }
    if (this.enableAdvancedFiltersChangeSub && !this.enableAdvancedFiltersChangeSub.closed) {
      this.enableAdvancedFiltersChangeSub.unsubscribe();
    }
  }

  clearField(field: FormControlNames) {
    if (this.form.get(field).valid) {
      this.form.get(field).setValue(null);
    }
  }

  submit() {
    const containers = this.containerSelectionForm.get(FormControlNames.SELECTED_CONTAINERS).value as Container[];
    this.store.dispatch(
      closeAddContainersToProductGroupDialog({
        productGroupId: this.data.productGroupId,
        containerIds: containers.map(c => c.id)
      })
    );
    this.dialogRef.close();
  }

  private updateFormControls() {
    const containerType = this.form.get(FormControlNames.CONTAINER_TYPE);
    const unit = this.form.get(FormControlNames.UNIT);
    const size = this.form.get(FormControlNames.SIZE);
    const sizeComparisonMode = this.form.get(FormControlNames.SIZE_COMPARISON_MODE);
    const allowAdvancedFilters = this.form.get(FormControlNames.ALLOW_ADVANCED_FILTERS);
    const enableAdvancedFilters = this.form.get(FormControlNames.ENABLE_ADVANCED_FILTERS);

    if (containerType.valid) {
      unit.enable();
      size.enable();
      sizeComparisonMode.enable();
      enableAdvancedFilters.enable();
    } else {
      unit.disable();
      size.disable();
      sizeComparisonMode.disable();
      enableAdvancedFilters.disable();
    }

    allowAdvancedFilters.setValue(
      this.data.allowedContainerTypes === AllowedContainerTypes.ONLY_ADVANCED ||
        this.data.allowedContainerTypes === AllowedContainerTypes.BOTH
    );
  }

  private onAdvancedFiltersActiveChange(enabled: boolean) {
    if (!enabled) {
      this.form.get(FormControlNames.CAP).setValue(null, {
        emitEvent: false
      });
      this.form.get(FormControlNames.SHAPE).setValue(null, {
        emitEvent: false
      });
      this.form.get(FormControlNames.COLOR).setValue(null, {
        emitEvent: false
      });
      this.form.updateValueAndValidity();
    }
  }

  private getAdvancedFilter(): Filter[] {
    const filters: Filter[] = [];
    if (this.data.allowedContainerTypes !== AllowedContainerTypes.BOTH) {
      filters.push(
        new Filter({
          fieldName: 'isAdvanced',
          value: this.data.allowedContainerTypes === AllowedContainerTypes.ONLY_ADVANCED ? 'true' : 'false',
          operator: FilterOperator.EQUALS
        })
      );
    }
    return filters;
  }
}
