import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { ProductGroupResponsibility } from '@rims/database';
import { capitalize, CrudQueryParameters, RimsQueryParam } from '@rims/lib';
import pluralize from 'pluralize-esm';
import { concat, Observable, of } from 'rxjs';
import { map, mergeMap, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { AddDialogComponent } from '../../shared/components/add-dialog/add-dialog.component';
import { RemoveDialogComponent } from '../../shared/components/remove-dialog/remove-dialog.component';
import { DataService } from '../../shared/services/data/data.service';
import { getEntity, getEntityUrlAndView } from '../../shared/utils/entity-utils';
import { getData, GetDataPayload } from '../data/data.actions';
import { clearCheckboxColor, removeSelection, setCheckboxColor } from '../metadata/metadata.actions';
import { AppState } from '../store.state';
import {
  closeAddDialog,
  closeRemoveDialog,
  CloseRemoveDialogPayload,
  noop,
  openAddDialog,
  OpenAddDialogPayload,
  openRemoveDialog,
  OpenRemoveDialogPayload,
  reloadAfterAdd,
  reloadAfterClose
} from './shared.actions';

@Injectable()
export class SharedEffects {
  constructor(
    private readonly actions: Actions,
    private readonly dialog: MatDialog,
    private readonly store: Store<AppState>,
    private readonly dataService: DataService
  ) {}

  openRemoveDialog = createEffect(() =>
    this.actions.pipe(
      ofType(openRemoveDialog),
      switchMap(payload => {
        const dialogRef = this.dialog.open<RemoveDialogComponent, OpenRemoveDialogPayload>(RemoveDialogComponent, {
          panelClass: 'remove-dialog',
          data: payload
        });
        this.store.dispatch(setCheckboxColor({ checkboxColor: 'warn', viewId: payload.viewId }));
        dialogRef.afterClosed().subscribe(() => {
          this.store.dispatch(clearCheckboxColor());
        });
        return of(noop());
      })
    )
  );

  closeRemoveDialog = createEffect(() =>
    this.actions.pipe(
      ofType(closeRemoveDialog),
      withLatestFrom(this.store.select(state => state.metadata)),
      switchMap(([payload, metadata]) => {
        const entity = getEntity(metadata, payload.key);
        const { url, view } = getEntityUrlAndView(metadata, payload.relationKey ?? payload.key);
        const { key: fromKey, options } = this.getOptionsAndKey(payload.fromName, payload.fromId);
        const params = this.getParams(payload, fromKey);

        return (
          payload.referenceName
            ? this.dataService.delete(entity.id, undefined, params, url, options)
            : this.dataService.bulkDelete(entity.id, payload.entityIds, undefined, undefined, options)
        ).pipe(
          map(() => {
            return reloadAfterClose({
              view,
              url,
              reloadAction: payload.reloadAction,
              entityIds: payload.entityIds,
              reloadActionPayload: payload.reloadActionPayload
            });
          })
        );
      })
    )
  );

  reloadAfterClose = createEffect(() =>
    this.actions.pipe(
      ofType(reloadAfterClose),
      mergeMap(({ view, url, reloadAction, entityIds, reloadActionPayload }) => {
        return [
          reloadAction
            ? reloadAction(reloadActionPayload)
            : getData(
                GetDataPayload.fromView(
                  {
                    ...view
                  },
                  {
                    url
                  }
                )
              ),
          removeSelection({
            viewId: view.id,
            selection: entityIds
          })
        ];
      })
    )
  );

  openAddDialog = createEffect(() =>
    this.actions.pipe(
      ofType(openAddDialog),
      switchMap(payload => {
        const config = {
          ...(payload.transfer ? { panelClass: 'transfer-dialog' } : { minWidth: '60%', maxWidth: '100%' }),
          data: payload
        };

        this.dialog.open<AddDialogComponent, OpenAddDialogPayload>(AddDialogComponent, config);
        return of(noop());
      })
    )
  );

  closeAddDialog = createEffect(() =>
    this.actions.pipe(
      ofType(closeAddDialog),
      withLatestFrom(this.store.select(state => state.metadata)),
      switchMap(([payload, metadata]) => {
        const { url, view } = getEntityUrlAndView(metadata, payload.relationKey ?? payload.key);
        const { options, records } = this.getOptionsAndRecords(payload);

        let body = null;

        // only relevant for 'product_group_item'
        if (payload.mismatch && payload.budiInfo) {
          const { active, isSterile, administeringMedicine, implantable, measuringFunction, reusable } =
            payload.budiInfo;

          const budiInfo = {
            active,
            isSterile,
            administeringMedicine,
            implantable,
            measuringFunction,
            reusable
          };

          body = { budiInfo };
        }

        const params = new HttpParams({
          fromObject: {
            // If true, no error is thrown if a to-be-created record already exists.
            // The existing record remains unchanged.
            ...(payload.multiple != null && { [CrudQueryParameters.IGNORE_DUPLICATES]: payload.multiple }),
            // If true, the users product owner permission will be validated for each product group provided in the body
            ...(payload.transfer != null && { [RimsQueryParam.TRANSFER_RESPONSIBILITIES]: payload.transfer })
          }
        });

        return this.dataService.create(`${url}`, records, options, body, params).pipe(
          map(() =>
            reloadAfterAdd({
              view,
              url,
              reloadAction: payload.reloadAction,
              reloadActionPayload: payload.reloadActionPayload,
              reloadActionSuccess: payload.reloadActionSuccess,
              selection: payload.selection
            })
          )
        );
      })
    )
  );

  reloadAfterAdd = createEffect(() =>
    this.actions.pipe(
      ofType(reloadAfterAdd),
      switchMap(({ view, url, reloadAction, reloadActionPayload, reloadActionSuccess, selection }) => {
        // start with reloadAction
        let reloadActionObservable: Observable<any>;

        if (reloadAction) {
          const reloadAction$ = of(reloadAction(reloadActionPayload)).pipe(tap(action => this.store.dispatch(action)));

          if (reloadActionSuccess) {
            // wait for success action if defined, to ensure reloadAction has finished
            reloadActionObservable = concat(
              reloadAction$,
              this.actions.pipe(ofType(reloadActionSuccess.type), take(1))
            );
          } else {
            // skip waiting if no success action is defined
            reloadActionObservable = reloadAction$;
          }
        } else {
          // no reloadAction provided
          reloadActionObservable = of(null);
        }

        // continue with other actions after reloadAction
        return reloadActionObservable.pipe(
          mergeMap(() => {
            const actions: Action[] = [
              getData(
                GetDataPayload.fromView(
                  {
                    ...view
                  },
                  {
                    url
                  }
                )
              )
            ];

            selection && actions.push(removeSelection({ viewId: view.id, selection }));

            return actions;
          })
        );
      })
    )
  );

  private getOptionsAndRecords(payload: any) {
    const createPayload = {} as any;

    // only relevant for 'product_group_responsibility'
    if (payload.department) {
      createPayload['department'] = payload.department;
    }

    const { key: toKey, options } = this.getOptionsAndKey(payload.toName, payload.toId);

    const elements = Array.isArray(payload.toId) ? payload.toId : [payload.toId];

    const records = elements.flatMap(element => {
      const id = payload.transfer ? element.productGroup : element;
      const referenceKey = this.getReferenceKey(payload.referenceIds, payload.referenceName, payload.toName);

      createPayload[toKey] = id;

      if (payload.transfer) {
        createPayload['department'] = element.department;
      }

      if (referenceKey) {
        createPayload[referenceKey] = payload.referenceIds;
      }

      return payload.entityIds.map(entityId => {
        const record = { ...createPayload, [payload.entityName]: entityId };

        // only relevant for 'product_group_item'
        if (payload.mismatch?.includes(entityId)) {
          record['mismatch'] = true;
        }

        return record;
      });
    });

    return { records, options };
  }

  /**
   * returns the params needed for the delete operation(s), including
   *  - the instance we remove from (e.g. product group, here 'fromKey')
   *  - the ids of the entities we want to remove (e.g. production sites, here 'entityKey')
   *  - the ids of the selected entities in case we only want to remove from those (e.g. items within a product group, here 'referenceKey')
   */
  private getParams(payload: CloseRemoveDialogPayload, fromKey: string) {
    if (!payload.referenceName || !payload.fromId || !fromKey) return;

    const entityKey = pluralize(payload.entityName).toLowerCase();
    let params = new HttpParams({
      fromObject: {
        [fromKey]: payload.fromId + '',
        [entityKey]: payload.entityIds.join(',')
      }
    });

    const referenceKey = this.getReferenceKey(payload.referenceIds, payload.referenceName, payload.fromName);
    if (referenceKey) {
      params = params.append(referenceKey, payload.referenceIds.join(','));
    }

    return params;
  }

  /**
   * returns the 'key' for the selected entity from which we remove or to which we add, @example 'selectedProductGroupContainers'
   */
  private getReferenceKey(referenceIds: number[], refName: string, fromToName: string) {
    if (!Array.isArray(referenceIds) || referenceIds.length < 1) return;

    // e.g. 'Product Group Items'
    const referenceName = capitalize(`${fromToName} ${pluralize(refName)}`);
    // e.g. 'selectedProductGroupItems'
    const referenceKey = `selected${referenceName.replace(/\s/g, '')}`;

    return referenceKey;
  }

  /**
   * returns
   *  - options with the 'runAsProductOwnerFor' property which will be sent with each delete or post request
   *  - the key of the instance we remove from ('fromKey') or the key of the instance we add to ('toKey'), e.g. 'productGroup' or 'item'
   *  - runAsProductOwnerFor will be omitted if there are several ids.
   *    This is relevant for transfer responsibilities where we need to validate product owner permissions for each group individually
   */
  private getOptionsAndKey(name: string, id: string | number | number[] | ProductGroupResponsibility[]) {
    let options = {};
    let key;

    if (name) {
      const [name1, name2] = name.split(' ');
      key = [name1, capitalize(name2)].join('');

      if (!Array.isArray(id)) {
        options = {
          runAsProductOwnerFor: {
            [key]: id
          }
        };
      }
    }

    return {
      options,
      key
    };
  }
}
