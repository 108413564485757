import { Pipe, PipeTransform } from '@angular/core';
import { ContainerType } from '@rims/database';

@Pipe({
  name: 'containerTypeFormat',
  standalone: true
})
export class ContainerTypeFormatPipe implements PipeTransform {
  static displayFunction(containerType?: ContainerType | null) {
    if (!containerType) {
      return '';
    }
    return `${containerType.name} (${containerType.shortName})`;
  }

  transform(containerType?: ContainerType | null) {
    return ContainerTypeFormatPipe.displayFunction(containerType);
  }
}
