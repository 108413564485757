import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { MetadataService } from '../../shared/services/metadata/metadata.service';
import { loadApplicationMetadata, loadApplicationMetadataSuccess } from './meta.actions';

interface VersionJson {
  appVersion: string;
  buildTime: string;
  commitHash: string;
}

@Injectable()
export class MetaEffects {
  loadApplicationMetadata = createEffect(() =>
    this.actions.pipe(
      ofType(loadApplicationMetadata),
      switchMap(() => {
        return this.http.get('/assets/_version/version.json').pipe(
          catchError(() => {
            return of({
              appVersion: 'No version information available.',
              buildTime: 'No build time information available.',
              commitHash: 'No commit hash information available.'
            });
          }),
          map(({ appVersion, buildTime, commitHash }: VersionJson) => {
            return loadApplicationMetadataSuccess({
              appVersion,
              buildTime,
              commitHash
            });
          })
        );
      })
    )
  );

  constructor(
    private readonly actions: Actions,
    private readonly http: HttpClient,
    private readonly metadataService: MetadataService,
    private readonly snackBar: MatSnackBar
  ) {}
}
